import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Dropzone from 'react-dropzone'

import uuid from 'node-uuid'

import axios from 'axios'
const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.delete['X-CSRF-TOKEN'] = csrf_token

import AttachmentFiles from './AttachmentFiles'

export default class Attachments extends Component {
  constructor(props) {
    super(props)

    this.state = {
      files: [],
      max_files: props.max_files || 1000,
      multiple: props.multiple || true,
      loading: true,
      manager_mode: false,
    }

    this.onDrop = this.onDrop.bind(this)
    this.loadFiles = this.loadFiles.bind(this)
  }

  loadFiles() {
    let params = {
      resource_type: this.props.resource_type,
      resource_id: this.props.resource_id,
      group_identifier: this.props.group_identifier,
      fixed_attachment_identifier: this.props.fixed_attachment_identifier,
    }

    axios.get(`/web_api/projects_management/attachments`, {params: params}).then((response) => {
      let files = response.data.data.map(file => file.attributes)
      this.setState({files: files, loading: false})
    })
  }

  componentWillMount() {
    this.loadFiles()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.resource_id != this.props.resource_id) {
      this.setState({loading: true}, () => {
        this.loadFiles()
      })
    }
  }

  onDrop(files) {
    let current_files = this.state.files

    if((files.length + current_files.length) <= this.state.max_files) {
      let files_map = files.map((file) => {
        return Object.assign(
          file,
          {
            preview: URL.createObjectURL(file),
            uploaded: false,
            uuid: uuid.v4(),
            extension: file.name.split('.').pop()
          }
        )
      })

      let files_data = files_map.map(file => {
        return {name: file.name, uuid: file.uuid, extension: file.extension, type: file.type }
      })

      let params = {
        resource_type: this.props.resource_type,
        resource_id: this.props.resource_id,
        files: files_data || [],
        group_identifier: this.props.group_identifier,
        fixed_attachment_identifier: this.props.fixed_attachment_identifier,
      }

      axios.post(`/web_api/projects_management/attachments/signed_urls`, {...params}).then((response) => {
        let data = response.data.signed_urls

        files_map = files_map.map((file) => {
          let upload_data = data[file.uuid]
          return Object.assign(file, {...upload_data})
        })

        this.setState({files: files_map.concat(current_files)})
      })
    }
  }

  removeFile = (file, id) => {
    let attachment_id = file.id || id

    axios.delete(`/web_api/projects_management/attachments/${attachment_id}`).then(() => {
      let new_files = []
      this.state.files.forEach(new_file => {
        if(new_file.id == attachment_id || new_file.uuid == file.uuid) {
        } else {
          new_files.push(new_file)
        }
      })

      this.setState({files: new_files})
    })
  }

  render() {
    let { files, max_files, multiple } = this.state
    let { can_create_attachment, can_delete_attachment, small_view, small_attachment_zone } = this.props

    let mode_style = {}
    if(this.state.manager_mode) {
      mode_style = {fontWeight: 650}
    }

    let dropzone_class = "dropzone py-4 mb-2"

    if (small_attachment_zone) {
      dropzone_class = "small-dropzone py-2"
    }

    return(
      <div>
        { can_create_attachment && files.length < max_files &&
          <div className='row'>
            <div className='col-md-12'>
              <Dropzone
                className={dropzone_class}
                multiple={multiple}
                onDrop={this.onDrop}>
                <center className="text-secondary"><u><span className="fw-bold">Choose files</span></u> or drag them here</center>
              </Dropzone>
            </div>
          </div>
        }

        <div className='row'>
          <div className='col-md-12'>
            { can_delete_attachment && this.state.files.length > 0 &&
              <div
                className='text-info cursor-pointer'
                style={mode_style}
                onClick={() => this.setState({manager_mode: !this.state.manager_mode})}>
                  Manager mode
              </div>
            }

            <AttachmentFiles
              files={this.state.files}
              resource_type={this.props.resource_type}
              resource_id={this.props.resource_id}
              group_identifier={this.props.group_identifier}
              fixed_attachment_identifier={this.props.fixed_attachment_identifier}
              small_view={small_view}
              can_delete_attachment={can_delete_attachment && this.state.manager_mode}
              remove_file={this.removeFile}
            />
          </div>
        </div>
      </div>
    )
  }
}
