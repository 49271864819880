import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import axios from 'axios'
import { ImagesLoader } from '../projects_management/helpers/Loaders'

import AttachmentFiles from '../projects_management/helpers/AttachmentFiles'

export default class ReferenceData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      files: [],
      max_files: 100,
      open: true,
    }

    this.handleOpen = this.handleOpen.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  fetchData() {
    let { resource_id, resource_type, field } = this.props
    let url = `/web_api/projects_management/${resource_type}/${resource_id}/reference_data`
    axios.get(url, {params: {field}})
      .then((response) => {
        let files = []
        if(response.data.data) {
          files = response.data.data.map(e => e.attributes )
        } else {
          files = response.data.map(e => e.attributes )
        }
        this.setState({loading: false, files: files})
      })
      .catch((error) => {
        console.error('Error fetching reference data:', error)
        this.setState({loading: false, files: []})
      })
  }

  componentDidMount() {
    this.fetchData()
  }

  handleOpen() {
    if(this.state.open) {
      this.setState({loading: true, files: [], open: false})
    } else {
      this.setState({loading: true, open: true}, () => {
        this.fetchData()
      })
    }
  }

  render() {
    let icon_class = "fa fa-chevron-right"

    if(this.state.open) {
      icon_class = "fa fa-chevron-down"
    }

    return(
      <Fragment>
        <div className='row mt-2'>
          <div className='col-md-12 text-secondary'>
            <small className='cursor-pointer' onClick={this.handleOpen}>
              Reference <i className={icon_class}></i>
            </small>
          </div>
        </div>
        { this.state.open &&
          <Fragment>
            { this.state.loading ? (
              <ImagesLoader />
            ) : (
              <div className='row mt-2 ms-2'>
                <div className='col-md-12'>
                  <AttachmentFiles
                    files={this.state.files}
                    resource_type={this.props.resource_type}
                    resource_id={this.props.resource_id}
                    comes_from_campodata_form={true}
                    updateInputValue={this.props.updateInputValue}
                    field_type="file"
                  />
                </div>
              </div>
            )}
          </Fragment>
        }
      </Fragment>
    )
  }
}
