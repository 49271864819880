import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import api from '../../../utils/pm_project_tasks_api'

import { Link } from 'react-router-dom'

import FileView from '../common/FileView'

import Lightbox from 'react-image-lightbox'

export default class TasksFiles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      groups: [],
      images: [],
      isOpen: false,
      photoIndex: 0,
    }

    this.loadFiles = this.loadFiles.bind(this)
  }

  loadFiles() {
    api.getProjectSubTasksFiles(this.props.project_task_id, this.props.classifier_id).then((response) => {
      let groups = []
      response.tasks.forEach((task) => {
        let files = []
        response.files.data.forEach((file) => {
          if(file.attributes.resource_id == task.id) {
            files.push(file.attributes)
          }
        })
        groups.push({
          task: task,
          files: files
        })
      })

      let images = []
      let image_index = 0

      groups.forEach((group) => {
        group.files.forEach((file) => {
          let is_image = ['png','jpg','jpeg','gif','heic'].includes((file.extension || "").toLowerCase())
          if(is_image) {
            file.index = image_index
            file.task_name = group.task.name,
            image_index = image_index + 1
            images.push(file)
          }
        })
      })

      this.setState({
        loading: false,
        groups: groups,
        images: images,
      })
    })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.resource_id != this.props.resource_id) {
      this.setState({groups: [], loading: true}, () => {
        this.loadFiles()
      })
    }
  }

  componentWillMount() {
    this.loadFiles()
  }

  render() {
    let i_style = {
      fontSize: '70px',
      marginLeft: '25px'
    }

    let { isOpen, images, photoIndex } = this.state
    let new_images = []

    let titles = []
    images.forEach((f) => {
      new_images.push(f.file_url)
      titles.push(<p className='mt-2'>{f.task_name} - {f.name}</p>)
    })

    images = new_images



    return(
      <div className='files-map px-3 py-2'>
        { this.state.loading ? (
          <center> Loading ...</center>
        ) : (
          <div>
            {this.state.groups.map((group, index) => {
              let {task, files} = group
              return(
                <div className='row mb-4 pt-4 px-2' key={index}>
                  <div className='col-md-12'>
                    <div className='header'>
                      <h5 className='d-inline'>{task.name}</h5>
                      <div className='d-inline pull-right'>
                        { false && <span className='ms-2 badge badge-warning'>{task.pending_notes} Notes Pending</span> }
                        <Link className='ms-2' to={`/projects_management/${this.props.resource_link}/` + task.id}>Details</Link>
                      </div>
                    </div>

                    <div className='body'>
                      { group.files.length > 0 ? (
                        <div className='row'>
                          {group.files.map((file, index) => {
                            return(
                              <FileView
                                key={index}
                                file={file}
                                onImageClick={() => this.setState({isOpen: true, photoIndex: file.index})}
                              />
                            )
                          })}
                        </div>
                      ) : (
                        <div className="text-secondary text-center" style={{marginTop: '20px'}}>
                          No files
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
            imageTitle={titles[photoIndex]}
          />
        )}

      </div>
    )
  }
}
