import React, { useState } from 'react'
import EasyEdit, {Types} from 'react-easy-edit';
import { useUpdateAttachment } from '../document_groups/hooks/useAttachments';

const EditClassifier = ({ type, attachment, resource_id, resource_type }) => {
  const [value, setValue] = useState(attachment.classifier_a || "")
  const { mutate: updateAttachment, isLoading } = useUpdateAttachment({resource_type, resource_id});

  const save = async (new_value) => {
    if(new_value == "") {
      await updateAttachment({id: attachment.id, classifier_a: null})
      setValue(null)
    } else {
      await updateAttachment({id: attachment.id, classifier_a: new_value})
      setValue(new_value)
    }
  };

  const cancel = () => {
  };

  return (
    <div>
      <EasyEdit
        type={Types.TEXT}
        onSave={save}
        placeholder={'Set Classifier'}
        onCancel={cancel}
        saveButtonLabel="Save"
        saveButtonStyle={'btn btn-sm btn-outline-primary me-1'}
        cancelButtonLabel="Cancel"
        cancelButtonStyle={'btn btn-sm btn-outline-secondary'}
        value={value}
        instructions=""
        attributes={{className: "form-control form-control-sm d-block mb-1"}}
      />
    </div>
  );
};

export default EditClassifier;
