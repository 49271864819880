import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import SideViewTabs from '../common/SideViewTabs'

import SupplierModal from './SupplierModal'

export default class SideView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open_modal: false
    }

    this.handleSave = this.handleSave.bind(this)
  }

  handleSave(record) {
    this.setState({open_modal: false}, () => {
      this.props.handleRecordSelect(record)
    })
  }

  render() {
    let { record } = this.props
    let { open_modal } = this.state

    return(
      <div className='card sticky-top'>
        { record ? (
          <div className="card-body">
            <div className='inventory-header'>
              <h4 className='mb-0'>
                {record.name}
                <a href="#"
                  onClick={() => this.setState({open_modal: true})}
                  className='ms-2'
                  style={{fontSize: '14px'}}>
                  Edit
                </a>
              </h4>
              <h4 style={{fontWeight: 100}}>
                {record.classifier_a}
              </h4>
            </div>
            <div className='inventory-desc mb-2'>
              {record.description}
            </div>
            { record.contact_phone &&
            <div className='inventory-phone'>
              <small className='text-uppercase text-secondary'>
                <strong>PHONE:</strong> {record.contact_phone}
              </small>
            </div>
            }
            { record.contact_email &&
            <div className='inventory-email'>
              <small className='text-uppercase text-secondary'>
                <strong>EMAIL:</strong> {record.contact_email}
              </small>
            </div>
            }

            <div className='inventory-extra mt-3'>
              <SideViewTabs
                resource_type="vendors"
                resource_id={record.id}
              />
            </div>
          </div>
        ) : (
          <div className="card-body" style={{padding: '100px 0px'}}>
            <center>Select a record to view info.</center>
          </div>
        )}
        { open_modal &&
          <SupplierModal
            is_open={open_modal}
            closeModal={() => {this.setState({open_modal: false})}}
            handleSave={this.handleSave}
            model={record}
          />
        }

      </div>
    )
  }
}
