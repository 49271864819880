import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import ReactTable from "react-table"

import inventoryApi from '../../../../utils/pm_inventory_api'

import BaseTable from '../../tables/BaseTable'
import { Link } from "react-router-dom"

import DebounceInput from 'react-debounce-input';

import ExportTableButton from '../../helpers/ExportTableButton'
import Select from 'react-select'
import NumericInput from 'react-numeric-input'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}


class EntryForm extends Component {
  constructor(props) {
    super(props)
    let disabled_unit_cost = false

    if(props.data && props.data.inventory_item.value && props.data.inventory_item.value != '$manual-entry') {
      disabled_unit_cost = true
    }

    let data = {...props.data}
    data['disabled_unit_cost'] = disabled_unit_cost

    this.state = {
      model: data || {
        description: "",
        quantity: 0,
        unit_cost: 0,
        sub_organization: {},
        inventory_item: {},
      },
      loading: false,
    }

    this.handleValue = this.handleValue.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.modelValid = this.modelValid.bind(this)
    this.formatOptionLabel = this.formatOptionLabel.bind(this)
  }

  handleValue(type, e) {
    let model = {...this.state.model}
    if(type == 'quantity') {
      model[type] = e
    } else if(type == 'unit_cost') {
      model[type] = e
    } else if(type == 'description') {
      model[type] = e.target.value
    } else if(type == 'sub_organization') {
      model[type] = e
    } else if(type == 'inventory_item') {
      model[type] = e
      if(e.organization_cost) {
        model['unit_cost'] = e.organization_cost
        model['disabled_unit_cost'] = true
      } else {
        model['unit_cost'] = null
        model['disabled_unit_cost'] = false
      }
    }

    this.setState({model})
  }

  modelValid() {
    let model = this.state.model
    return (
      model.description &&
      model.quantity &&
      model.quantity > 0 &&
      model.unit_cost &&
      model.unit_cost > 0 &&
      model.sub_organization &&
      model.inventory_item
    );
  }

  handleSave() {
    let { model } = this.state
    if(this.state.loading || !this.modelValid()) {
      return
    }

    this.setState({loading: true}, () => {
      let params = {...model, project_id: this.props.project_id}

      if(model.id) {
        inventoryApi.updateEntry(model.id, params).then((response) => {
          let description = model.description
          this.setState({
            loading: false,
            entry_saved: description,
          }, () => {
            this.props.reloadTable()
          })
        })
      } else {
        inventoryApi.createEntry(params).then((response) => {
          let description = model.description
          this.setState({
            loading: false,
            model: {description: "", quantity: 0, unit_cost: 0, sub_organization: {}, inventory_item: {}},
            entry_saved: description,
          }, () => {
            this.props.reloadTable()
          })
        })
      }
    })
  }

  formatOptionLabel(props) {
    if (props.value == "$manual-entry") {
      return (
        <div className="d-flex flex-stack border-top ">
          <div className="d-flex align-items-center">
            <p className="fs-7 fw-bold text-gray-900 mt-2 mb-2">{props.label}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-stack">
          <div className="d-flex align-items-center">
            <p className="fs-7 text-gray-900 mb-0">{props.label}</p>
          </div>
        </div>
      );
    }
  }

  render() {
    let { model, loading, entry_saved } = this.state
    let { sub_organizations, inventory_items } = this.props
    let disabled = !this.modelValid() || loading
    let save_label = model.id ? 'Update' : 'Add'
    let save_info = model.id ? 'updated' : 'created'
    let total = undefined
    if(model.quantity && model.unit_cost) {
      total = model.quantity * model.unit_cost
      total = total.toLocaleString(undefined, { minimumFractionDigits: 2 })
    }

    return (
      <div className="card">
        <div className="card-body">
          {entry_saved && (
            <div className="row">
              <div className="col-md-12">
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <strong>{entry_saved}</strong> was {save_info}.
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Contractor</label>
                <Select
                  styles={selectStyles}
                  value={model.sub_organization}
                  isMulti={false}
                  onChange={(e) => this.handleValue("sub_organization", e)}
                  options={sub_organizations}
                  placeholder={"Select a contractor"}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Inventory Item Type</label>
                <Select
                  formatOptionLabel={this.formatOptionLabel}
                  styles={selectStyles}
                  value={model.inventory_item}
                  isMulti={false}
                  onChange={(e) => this.handleValue("inventory_item", e)}
                  options={inventory_items}
                  placeholder={"Select an inventory item"}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Quantity</label>
                    <NumericInput
                      onChange={(e) => this.handleValue("quantity", e)}
                      value={model.quantity}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Unit Price</label>
                    <NumericInput
                      onChange={(e) => this.handleValue("unit_cost", e)}
                      value={model.unit_cost}
                      disabled={model.disabled_unit_cost}
                      className="form-control"
                    />
                    {total ? (
                      <h6 className="mt-1">Total ${total} </h6>
                    ) : (
                      <h6 className="mt-1">Total $-</h6>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Description</label>
                <input
                  value={model.description}
                  onChange={(e) => this.handleValue("description", e)}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <center>
                <button
                  style={{ marginTop: "32px" }}
                  onClick={this.props.onClose}
                  className="btn btn-light"
                >
                  Cancel
                </button>
                <button
                  disabled={disabled}
                  style={{ marginTop: "32px" }}
                  onClick={this.handleSave}
                  className="btn btn-primary ms-2"
                >
                  {save_label}
                </button>
              </center>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const exportRows = (data, extra_columns) => {
  let rows = []

  data.forEach((record) => {
    let agent = record.sub_organization_id ? record.sub_organization.name : record.vendor.name

    let row = [
      record.submitted_at_label,
      record.inventory_item.name,
      record.project.name,
      agent,
      record.amount,
      record.inventory_item.contractor_amount,
      record.pending
    ]

    rows.push(row)
  })

  return rows
}

const tableHeaders = (extra_columns) => {
  let headers = ['Reported Date', 'Materials', 'Project', 'Agent', 'Item Qty.', 'Cost', 'Pending']

  extra_columns.forEach((column, index) => {
    headers.push(column.label)
  })

  return headers
}


export default class ListTable extends BaseTable {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      pages: null,
      loading: true,
      project_id: props.project_id,
      open_form: false,
      hovered_item: null,
    }

    this.columns = this.columns.bind(this)
    this.handleItemEdit = this.handleItemEdit.bind(this)
  }

  handleItemEdit(item) {
    if(typeof item === 'object') {
      let edit_data = {
        id: item.id,
        quantity: item.quantity,
        unit_cost: parseFloat(item.unit_cost),
        description: item.inventory_item.description,
        inventory_item: {
          value: item.inventory_item.id,
          label: item.inventory_item.name
        },
        sub_organization: {
          label: item.sub_organization.name,
          value: item.sub_organization_id,
        }
      }

      this.setState({edit_data: edit_data, open_form: true})
    }
  }

  handleRowMouseEnter(id) {
    this.setState({hovered_item: id})
  }

  handleRowMouseLeave() {
    this.setState({hovered_item: null})
  }

  getTdProps(state, rowInfo, column, instance) {
    const { selection } = this.state;
    let { auth } = this.props
    if (column.id == '_selector' || column.id == '_expander' ) {
      return super.getTdProps(state, rowInfo, column, instance)
    } else {
      return {

        onMouseEnter: (e, handleOriginal) =>{
          if(rowInfo) {
            this.handleRowMouseEnter(`${rowInfo.original.id}`)
          }
        },
         onMouseLeave: (e, handleOriginal) =>{
          this.handleRowMouseLeave()
        },

        onClick: (e, handleOriginal) => {
          if($(e.target).hasClass('prevent-select')) {
          } else {
            if(rowInfo) {
              this.handleItemEdit(`${rowInfo.original}`)
            }
          }
        }
      }
    }
  }

  fetchData(state, instance, maintain_selection) {
    let selection = maintain_selection ? this.state.selection : []
    this.setState({
      selection: selection,
      page: state.page,
      per_page: state.pageSize,
      loading: true,
      filtered: state.filtered,
      sorted: state.sorted,
      project_id: this.state.project_id,
    }, () => {
      let filters = {
        ...this.state.filters,
        per_page: state.pageSize,
        page: state.page,
        filtered: state.filtered,
        sorted: state.sorted,
        project_id: this.state.project_id,
      }
      inventoryApi.formInventoryEntries(filters).then((data) => {
        let records = data.data.map((record) => record.attributes)
        let pages = data.meta.total_pages
        this.setState({data: records, pages: pages, loading: false})
      })
    })
  }

  columns() {
    let _columns = [
      {
        Header: 'Reported Date',
        accessor: 'submitted_at',
        filterable: false,
        sortable: true,
        className: 'small text-uppercase',
        width: 150,
        Cell: ({original}) => {
          let res = original.submitted_at_label.split("-")
          return(
            <small>
              { original.manual_entry ? (
                <Fragment>
                  <span className='d-block'>{res[0]}</span>
                  {res[1]}

                  {  this.state.hovered_item == original.id &&
                      !this.props.sub_organization_user &&
                      this.props.project_id &&
                    <span className='cursor-pointer' onClick={() => this.handleItemEdit(original)}>
                      <i className='prevent-select fa fa-pencil ms-2 me-3 text-primary' style={{fontSize: '15px', cursor: 'pointer'}}></i>
                    </span >
                  }

                </Fragment>
              ) : (
                <a href={"/submissions/" + original.submission_id + "/webview"} target="_blank" className='text-primary' style={{cursor: 'pointer'}}>
                  <span className='d-block'>{res[0]}</span>
                  {res[1]}
                </a>
              )}
            </small>
          )
        }
      },
      {
        Header: 'Materials',
        accessor: 'inventory_item',
        sortable: true,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              whiteSpace: 'unset'
            }
          }
        },
        Cell: ({original}) => {
          return (
            <>
              {original.manual_entry ? (
                <small>
                  {original.inventory_item.name}
                  {original.inventory_item.description
                    ? ` - ${original.inventory_item.description}`
                    : ""}
                </small>
              ) : (
                <small>{original.inventory_item.name}</small>
              )}
            </>
          );
        },
        Filter: ({ filter, onChange }) => (
          <DebounceInput
            value={filter ? filter.value : ''}
            name='q-inventory_item'
            placeholder='All'
            type='search'
            className="form-control form-control-sm"
            autoComplete='off'
            minLength={3}
            debounceTimeout={400}
            onChange={event => onChange(event.target.value)}
          />
        )
      }
    ]

    if(!this.props.project_id) {
      _columns.push({
        Header: 'Project',
        accessor: 'project',
        sortable: true,
        filterable: this.props.project_id ? false : true,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              whiteSpace: 'unset'
            }
          }
        },
        Cell: ({original}) => {
          return(
            <small>
              {original.project.name}
            </small>
          )
        },
        Filter: ({ filter, onChange }) => (
          <DebounceInput
            value={filter ? filter.value : ''}
            name='q-project'
            placeholder='All'
            type='search'
            className="form-control form-control-sm"
            autoComplete='off'
            minLength={3}
            debounceTimeout={400}
            onChange={event => onChange(event.target.value)}
          />
        )
      })
    }

    _columns = _columns.concat([
      {
        Header: 'Agent',
        accessor: 'agent',
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              whiteSpace: 'unset'
            }
          }
        },
        sortable: false,
        Cell: ({original}) => {
          let value = original.sub_organization_id ? original.sub_organization.name : original.vendor.name
          return(
            <small>
              {value}
            </small>
          )
        },
        Filter: ({ filter, onChange }) => (
          <DebounceInput
            value={filter ? filter.value : ''}
            name='q-agent'
            placeholder='All'
            type='search'
            className="form-control form-control-sm"
            autoComplete='off'
            minLength={3}
            debounceTimeout={400}
            onChange={event => onChange(event.target.value)}
          />
        )
      },
      {
        Header: 'Item Qty.',
        accessor: 'amount',
        filterable: false,
        sortable: true,
        width: 100,
        Cell: ({original}) => {
          let class_style = original.amount_rounded > 0 ? "text-primary" : "text-danger"
          return(
            <small className={class_style}>
              {original.amount} {original.inventory_item.unit}
            </small>
          )
        }
      },
    ])

    let { warehouses } = this.props
    if(warehouses.length > 1) {
      _columns.push(
        {
          Header: 'Warehouse',
          accessor: 'warehouse',
          sortable: true,
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                whiteSpace: 'unset'
              }
            }
          },
          Cell: ({original}) => {
            return(
              <small>
              {original.warehouse.name}
              </small>
            )
          },
          Filter: ({ filter, onChange }) => (
            <DebounceInput
            value={filter ? filter.value : ''}
            name='q-warehouse'
            placeholder='All'
            type='search'
            className="form-control form-control-sm"
            autoComplete='off'
            minLength={3}
            debounceTimeout={400}
            onChange={event => onChange(event.target.value)}
            />
          )
        }
      )
    }

    _columns.push(
      {
        Header: 'Contractor $',
        accessor: 'contractor_amount',
        sortable: false,
        filterable: false,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              whiteSpace: 'unset'
            }
          }
        },
        Cell: ({original}) => {
          return(
            <small>
              {original.inventory_item.contractor_amount}
            </small>
          )
        }
      }
    )

    _columns.push(
      {
        Header: 'Pending $',
        accessor: 'pending',
        sortable: false,
        filterable: false,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              whiteSpace: 'unset'
            }
          }
        },
        Cell: ({original}) => {
          return(
            <small>
              {original.pending}
            </small>
          )
        }
      }
    )

    return _columns
  }

  render() {
    let { data, pages, loading, open_form } = this.state

    let headers = tableHeaders([])
    let export_rows = exportRows(this.state.data, [])

    return(
      <Fragment>
        <div className='row'>
          <div className='col-md-12'>
            <ExportTableButton
              export_rows={export_rows}
              headers={headers}
              filename={'historical_in_out'}
              loading={loading}
            />

            { this.props.project_id && !this.props.sub_organization_user &&
              <Fragment>
                <button className=" ms-2 btn btn-primary btn-sm" onClick={()=> this.setState({open_form: true})}>
                  Add Entry
                </button>
              </Fragment>
            }
          </div>
        </div>

        { open_form &&
          <div className='row mt-4 mb-2'>
            <div className='col-md-12'>
              <EntryForm
                sub_organizations={this.props.sub_organizations}
                inventory_items={this.props.inventory_items}
                project_id={this.props.project_id}
                data={this.state.edit_data}
                onClose={()=> this.setState({open_form: false, edit_data: undefined})}
                reloadTable={() => this.fetchData(this.state)}
              />
            </div>
          </div>
        }

        <div className='row mt-2'>
          <div className='col-md-12'>
            <ReactTable
              className="-striped -highlight"
              data={data}
              columns={this.columns()}
              defaultPageSize={20}
              pageSizeOptions= {[20, 200, 2000]}
              manual
              filterable
              pages={pages}
              loading={loading}
              getTdProps={this.getTdProps}
              onFetchData={this.fetchData}
              defaultSorted={[{ id: "submitted_at", desc: true }]}
              minRows={2}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}
