import React, { Fragment, useState, useEffect } from 'react'
import { render } from 'react-dom'
import L from 'leaflet';

const defaultMarkerColor = "#4699DF"

const activeIcon = () => {
  const size = 24;

  let markerHtmlStyles = `
    background-color: ${defaultMarkerColor};
    width: ${size}px;
    height: ${size}px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #FFFFFF;
    border-radius: 50% 50% 50% 0;
    transform: rotate(-45deg);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
  `
  const innerDotStyles = `
    width: ${size / 3}px;
    height: ${size / 3}px;
    background-color: #FFFFFF;
    border-radius: 50%;
    transform: rotate(45deg);
  `;

  const icon = L.divIcon({
    html: `<div style="${markerHtmlStyles}"><div style="${innerDotStyles}"></div></div>`,
    className: 'custom-div-icon',
    iconSize: [size, size],
    iconAnchor: [size / 2, size]
  });

  return(icon)
}

const MapComponent = (props) => {
  const [map, setMap] = useState(undefined)
  let draggable = props.view != "show"
  let map_id = `map-${props.input_key}-${props.item_group}`

  if(props.pending_submission) {
    map_id = `map-${props.input_key}-${props.item_group}-${props.pending_submission.id}`
  }

  const initializeMarker = (created_map) => {
    let marker = L.marker([props.point.latitude, props.point.longitude], {draggable: draggable, icon: activeIcon()})
    marker.on('dragend', (event) => {
      let marker = event.target;
      let position = marker.getLatLng();

      props.updatePoint({latitude: position.lat, longitude: position.lng})
    })

    const layers = [
      marker
    ];
    const featureGroup = L.featureGroup(layers).addTo(created_map);
    created_map.fitBounds(
      featureGroup.getBounds(), {
        padding: [70, 70],
        animate: false,
      }
    );

    //One way to zoom out
    setTimeout(function() {
      created_map.setZoom(created_map.getZoom() - 2);
    }, 300);

  }

  useEffect(() => {
    if(props.view == 'edit' || (props.point.latitude && props.point.longitude)) {
      let map = L.map(map_id).setView([0, 0], 0);
      let layer_1 = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      });
      let layer_2 = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
        maxZoom: 20,
        subdomains:['mt0','mt1','mt2','mt3']
      })

      let baselayers = {
        "Street": layer_1,
        "Satellite": layer_2,
      };

      let overlays = {}

      L.control.layers(baselayers, overlays).addTo(map);

      layer_1.addTo(map)
      setMap(map)
      initializeMarker(map)
    }
  }, [])

  return(
    <Fragment>
      { ((props.view == 'edit') || (props.point.latitude && props.point.longitude)) ? (
        <div id={map_id} style={{height: '280px'}}>
        </div>
      ) : (
        <div className="border rounded" style={{height: '100px'}}>
          <center className='text-secondary' style={{marginTop: '32px'}}>
            <small>Empty Map</small>
          </center>
        </div>
      )}

      { props.point.latitude && props.point.longitude &&
        <center className='text-secondary'>
          Lat: {props.point.latitude} Lon: {props.point.longitude}
        </center>
      }
    </Fragment>
  )

}


export default MapComponent
