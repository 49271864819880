import React from 'react'
import { useGetDocumentGroups } from '../../document_groups/hooks/useDocumentGroups';
import Attachments from '../../helpers/Attachments';

const AttachmentDocumentGroups = ({
  document_groups: document_groups_filter,
}) => {
  const {
    isLoading: isLoadingDocumentGroups,
    data: documentGroups,
    refetch: refetchDocumentGroups,
  } = useGetDocumentGroups({ document_groups_filter });

  return (
    <>
      {documentGroups && (
        <div className="row mt-2">
          <div className="col-md-12">
            <h5 className='text-uppercase text-secondary'>Reference Documents</h5>
          </div>

          {documentGroups.map((document_group) => {
            return (
              <div className="col-md-12 px-4 mt-2" key={document_group.id}>
                <h6 className="text-secondary">{document_group.name}</h6>

                <Attachments
                  resource_id={document_group.id}
                  resource_type={"document_groups"}
                  can_create_attachment={false}
                  can_delete_attachment={false}
                />
              </div>
            )
          })}
        </div>
      )}
    </>
  );
};

export default AttachmentDocumentGroups;
