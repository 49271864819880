import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import axios from 'axios';

import Modal from 'react-modal'

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
     left              : 0,
     right             : 0,
     bottom            : 0,
     backgroundColor   : 'rgba(0, 0, 0, 0.60)',
     zIndex            : 101
   },
   content : {
     position                   : 'absolute',
     top                        : '5%',
     left                       : '5%',
     right                      : '5%',
     bottom                     : '10%',
     border                     : '1px solid #ccc',
     background                 : '#fff',
     overflow                   : 'auto',
     WebkitOverflowScrolling    : 'touch',
     borderRadius               : '0px',
     outline                    : 'none',
     padding                    : '20px'

   }
 }

const ChangeHistoryModal = ({ handleClose, task_order, project_task }) => {
  const [loading, setLoading] = React.useState(true)
  const [diff, setDiff] = React.useState([])

  const getDiff = async () => {
    setLoading(true)
    const response = await axios.get(`/web_api/projects_management/project_tasks/${project_task.id}/task_orders/${task_order.id}/diff_history`)
    setLoading(false)
    setDiff(response.data.diff_map)
  }

  React.useEffect(() => {
    getDiff()
  }, [])

  return (
    <Modal style={customStyles} ariaHideApp={false} isOpen={true}>
      <div className="m-2">
        <div className="row px-2">
          <div className="col-md-6">
            <div className="project-meta">
              <h4>Task Order</h4>
            </div>

            <div className="pm-breadcrumb reset-box-sizing">
              <span className="me-2">{project_task.project.name}</span> /{" "}
              {task_order.name}
            </div>
          </div>
          <div className="col-md-6">
            <a
              onClick={handleClose}
              className="pull-right cursor-pointer d-print-none"
              style={{ fontSize: "24px" }}
            >
              <i className="fa fa-times text-secondary"></i>
            </a>
          </div>
        </div>

        <div className="row px-2">
          {loading ? (
            <div className="col-md-12 d-flex justify-content-center mt-2">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <Fragment>
              {diff.map((zone, index) => {
                return (
                  <div className="col-md-12 mt-2" key={index}>
                    <h5>{zone.title}</h5>
                    {zone.diff.split("\n").map((line, index) => {
                      if(line.startsWith("+")){
                        return <p key={index} className="mb-1" style={{backgroundColor: '#e6f9ea', fontSize: '14px'}}>{line}</p>
                      } else if(line.startsWith("-")){
                        return <p key={index} className="mb-1" style={{backgroundColor: '#f5abab', fontSize: '14px', textDecoration: 'line-through'}}>{line}</p>
                      } else {
                        return <p key={index} className="mb-1" style={{fontSize: '14px'}}>{line}</p>
                      }
                    })}
                  </div>
                );
              })}
            </Fragment>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ChangeHistoryModal
