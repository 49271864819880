import React from "react";
import moment from 'moment'
import RightArrowSvg from "./RightArrowSvg";
import ProjectTaskLabel from "../ProjectTaskLabel";

const ProjectTasksQuantityUpdated = ({entry, from_group}) => {
  let { data, project_task } = entry
  let { new_quantity, past_quantity } = data

  return (
    <li className="timeline-item mb-4">
      { project_task && !from_group &&
        <ProjectTaskLabel project_task={project_task}/>
      }

      <h6 className="mb-1 small">
        quantity updated
      </h6>

      <div>

        {past_quantity}

        <RightArrowSvg />

        {new_quantity}

      </div>

      {!from_group && (
        <small className="text-secondary mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksQuantityUpdated
