import React, { Component, Fragment } from 'react'

import { ShowHeaderLoader} from '../../helpers/Loaders'
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';

import GeneratorProjectTaskModal from '../../project_tasks/GeneratorProjectTaskModal.js'

export default class GeneratorShowHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open_new_modal: false,
    }

    this.handleBack = this.handleBack.bind(this)
    this.updateProjectTask = this.updateProjectTask.bind(this)
  }

  handleBack() {
    this.props.navigate()
  }

  updateProjectTask(project_task) {
    this.setState({open_new_modal: false}, () => {
      this.props.updateProjectTask(project_task)
    })
  }

  render() {
    let project_task = this.props.project_task
    let { parents, project_task_type } = project_task
    let items = [
      (<BreadcrumbsItem key='bt1' onClick={() => this.props.navigate('/projects_management/projects', true)} text="Projects" />),
      (<BreadcrumbsItem key='bt2'onClick={() => this.props.navigate(`/projects_management/projects/${project_task.project.id}`, true)} text={project_task.project.name} />),
      (<BreadcrumbsItem key='bt3' onClick={() => this.props.navigate(`/projects_management/projects/${project_task.project.id}?tab=${project_task.project_group.id}`, true)} text={project_task.project_group.name} />)
    ]

    parents.forEach((parent) => {
      let path = ''
      if(parent.tree_depth % 2 == 0) {
        path = `/projects_management/project_tasks/${parent.id}`
      } else {
        path = `/projects_management/project_tasks/${parent.parent_id}?classifier_id=${parent.id}`
      }
      items.push(
        (<BreadcrumbsItem key={parent.id} onClick={() => this.props.navigate(path, true)} text={parent.name} />)
      )
    })
    let from_job = !!project_task.project_task_importer_job_id
    let adhoc = project_task.adhoc
    let name = (from_job || adhoc) ? project_task.name : (project_task.project_task_type.name || project_task.name)

    let classDefinition = 'bg-white my-3 px-4'

    let { task_generator } = project_task_type

    return (
      <Fragment>
        {this.props.loading ? (
          <ShowHeaderLoader />
        ) : (
          <div className={classDefinition}>
            <div className="pm-breadcrumb reset-box-sizing">
              <BreadcrumbsStateless
                isExpanded={this.state.isExpanded}
                onExpand={(e) => this.expand(e)}
              >
                {items}
              </BreadcrumbsStateless>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="project-meta d-flex align-items-center">
                  <h4 className="d-inline mb-0 me-4">{name}</h4>
                  {project_task.access.can_generate_task && (
                    <button
                      className="btn btn-sm btn-outline-primary"
                      onClick={() => this.setState({ open_new_modal: true })}
                    >
                      <i className="fa fa-plus"></i> New{" "}
                      {task_generator.entry_label || "Entry"}
                    </button>
                  )}
                </div>
              </div>
            </div>

            {this.state.open_new_modal && (
              <GeneratorProjectTaskModal
                project_task={project_task}
                closeModal={() => this.setState({ open_new_modal: false })}
                is_open={this.state.open_new_modal}
                updateProjectTask={this.updateProjectTask}
                user={this.props.user}
              />
            )}
          </div>
        )}
      </Fragment>
    );
  }
}
