import React, { Component, Fragment, useEffect, useState } from 'react'

import api from '../../utils/pm_api'

import { ShowHeaderLoader, ShowTabsLoader } from './helpers/Loaders'

import Table from './grouped_tables/ProjectProjectTasksTable'
import Invoices from './invoices/Invoices'

import TasksFiles from './TasksFiles'

import Comments from './helpers/Comments'
const queryString = require('query-string')

import EditProjectModal from './project_tasks/EditProjectModal.js'
import DetailsSidebar from './common/DetailsSidebar'

import ConfigurationModal from './projects/ConfigurationModal'
import ReportsModal from './projects/ReportsModal'
import RelationshipsModal from './projects/RelationshipsModal'
import SubOrganizationHeader  from './sub_organizations/SubOrganizationHeader'

import InventoryInOut from './inventory/InventoryInOut'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const ShowHeader = ({ project_id, auth, openInvoices, openInventory, reloadProject }) => {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openConfigurationModal, setOpenConfigurationModal] = useState(false);
  const [openRelationshipsModal, setOpenRelationshipsModal] = useState(false);
  const [openReportsModal, setOpenReportsModal] = useState(false);
  const [processingTaskOrder, setProcessingTaskOrder] = useState(false);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/projects_management/projects');
  };

  const updateProject = (updatedProject) => {
    setOpenEditModal(false);
    setProject(updatedProject);
  };

  const reloadProjectHandler = () => {
    setOpenConfigurationModal(false);
    setOpenRelationshipsModal(false);
    reloadProject();
  };

  const processTaskOrder = () => {
    if (processingTaskOrder) {
      return;
    }

    setProcessingTaskOrder(true);
    api.projectProcessTaskOrder(project_id).then(() => {
      reloadProject();
    });
  };

  useEffect(() => {
    document.getElementsByClassName('sub-navbar')[0].style.display = 'none';
    api.getProject(project_id).then((response) => {
      if (response.data) {
        document.title = response.data.attributes.name;
        setProject(response.data.attributes);
        setLoading(false);
      }
    });

    return () => {
      document.getElementsByClassName('sub-navbar')[0].style.display = 'block';
    };
  }, [project_id]);

  if (loading) {
    return <ShowHeaderLoader />;
  }

  let policy = auth.projects;

  return (
    <Fragment>
      {project.project_type === 'sub_organization_profile' ? (
        <SubOrganizationHeader
          navigate={navigate}
          sub_organization_id={project.sub_organization.id}
          auth={auth}
          onManageUsers={false}
          project={project}
        />
      ) : (
        <div className='bg-white px-4 py-3'>
          <div className='row d-print-none'>
            <div className='col-md-12'>
              <div className='my-2 d-flex justify-content-between bd-highlight'>
                <small onClick={handleBack} className="bd-highlight text-secondary text-uppercase" style={{ cursor: 'pointer' }}>
                  <i className='fa fa-chevron-left'></i> Back to projects
                </small>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-7'>
              <div className='project-meta mb-3'>
                <h4>{project.name}</h4>
                {project.address && <p className="font-weight-light"><i className="fa fa-home me-1"></i> {project.address}</p>}
                {project.name_secondary && (
                  <p className="font-weight-light">
                    <i className="fa fa-phone me-1"></i> {project.name_secondary}, {project.description}
                  </p>
                )}
              </div>
              {project.access.can_process_task_order && (
                <div className="alert alert-secondary" role="alert">
                  {processingTaskOrder ? (
                    <span>
                      <i className="mt-1 text-primary fa fa-spinner fa-spin fa-1x fa-fw"></i> Processing task orders.
                    </span>
                  ) : (
                    <span>
                      New Task Orders are generated automatically every 15 minutes. You can generate it now with the
                      <a className='mx-2 text-primary cursor-pointer' onClick={processTaskOrder}>Process Now</a> button.
                    </span>
                  )}
                </div>
              )}
            </div>

            <div className='col-md-5'>
              <div className='project-status mb-3'>
                <div>
                  <i className='fa fa-square' style={{ color: project.state.color, fontSize: '20px' }}></i>{' '}
                  <h5 style={{ display: 'inline-block' }}>{project.state.label || 'Without status'}</h5>
                  {project.access.can_edit && (
                    <a href="#" className="ps-3 d-print-none" onClick={() => setOpenEditModal(true)}>Edit</a>
                  )}
                </div>

                <h6>
                  <small className='text-secondary text-uppercase'>Contractor: </small> {project.sub_organization.name || "Unassigned"}
                </h6>

                <small className='d-print-none'>
                  {project.access.can_view_details && (
                    <span className='text-nowrap'>
                      <i className='fa fa-expand me-1 text-secondary font-weight-light'></i>
                      <a className='text-info me-3' href="#" onClick={() => setOpenDetailsModal(!openDetailsModal)}>Project Details</a>
                    </span>
                  )}
                  {policy.can_configure && project.has_layered_templates && (
                    <span className='text-nowrap'>
                      <i className='fa fa-cog me-1 text-secondary'></i>
                      <a className='text-info me-3' href="#" onClick={() => setOpenConfigurationModal(true)}>Configuration</a>
                    </span>
                  )}
                  {policy.can_add_relationships && project.has_relationships && (
                    <span className='text-nowrap'>
                      <i className='fa fa-sitemap me-1 text-secondary'></i>
                      <a className='text-info me-3' href="#" onClick={() => setOpenRelationshipsModal(true)}>Relationships</a>
                    </span>
                  )}
                  {policy.can_list_invoices && project.has_invoices && (
                    <span className='text-nowrap'>
                      <i className='fa fa-money me-1 text-secondary'></i>
                      <a className='text-info me-3' href="#" onClick={openInvoices}>Invoices</a>
                    </span>
                  )}
                  {project.access.can_view_inventory_on_project && (
                    <span className='text-nowrap'>
                      <i className='fa fa-truck me-1 text-secondary'></i>
                      <a className='text-info me-3' href="#" onClick={openInventory}>Inventory</a>
                    </span>
                  )}
                  {project.access.can_view_reports_on_project && (
                    <span className='text-nowrap'>
                      <i className='fa fa-file me-1 text-secondary'></i>
                      <a className='text-info me-3' href="#" onClick={() => setOpenReportsModal(true)}>Reports</a>
                    </span>
                  )}
                </small>
              </div>
            </div>
          </div>

          {openEditModal && (
            <EditProjectModal
              project={project}
              closeModal={() => setOpenEditModal(false)}
              is_open={openEditModal}
              updateProject={updateProject}
            />
          )}

          {openConfigurationModal && (
            <ConfigurationModal
              project={project}
              closeModal={() => setOpenConfigurationModal(false)}
              is_open={openConfigurationModal}
              reloadProject={reloadProjectHandler}
            />
          )}

          {openReportsModal && (
            <ReportsModal
              project={project}
              closeModal={() => setOpenReportsModal(false)}
              is_open={openReportsModal}
            />
          )}

          {openRelationshipsModal && (
            <RelationshipsModal
              project={project}
              closeModal={() => setOpenRelationshipsModal(false)}
              is_open={openRelationshipsModal}
              reloadProject={reloadProjectHandler}
            />
          )}

          {openDetailsModal && (
            <DetailsSidebar
              object={project}
              resource_type='projects'
              resource_id={project.id}
              close={() => setOpenDetailsModal(false)}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

class ShowGroupInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open_comments: false ,
    }
  }

  render() {
    let { project_group } = this.props

    return(
      <Fragment>
        <div className='row'>
          <div className='col-md-12 d-print-none'>
            <Comments
              resource_type="project_groups"
              resource_id={project_group.id}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

const ShowTabs = ({ project_id, tab, auth }) => {
  const [loading, setLoading] = useState(true);
  const [projectGroups, setProjectGroups] = useState([]);
  const [activeProjectGroup, setActiveProjectGroup] = useState({});
  const [viewType, setViewType] = useState('project_tasks');
  const navigate = useNavigate();
  const location = useLocation();

  const changeActiveProjectGroup = (pm) => {
    setActiveProjectGroup(pm);
    const newLocation = `${location.pathname}?tab=${pm.id}`;
    navigate(newLocation);
  };

  useEffect(() => {
    api.getProjectProjectGroups(project_id).then((response) => {
      let group = response[0];
      if (tab) {
        const foundGroup = response.find((e) => e.id.toString() === tab.toString());
        if (foundGroup) {
          group = foundGroup;
        }
      }
      setProjectGroups(response);
      setActiveProjectGroup(group);
      setLoading(false);
    });
  }, [project_id, tab]);

  if (projectGroups.length === 0) {
    return (
      <div className='my-2'>
        <center className='py-4 text-secondary'>
          <Fragment>
            {!loading ? (
              <span>No Tasks assigned for this project</span>
            ) : (
              <span>Loading ...</span>
            )}
          </Fragment>
        </center>
      </div>
    );
  } else {
    return (
      <Fragment>
        {loading ? (
          <ShowTabsLoader />
        ) : (
          <div className='bg-white px-4 py-3 project-groups'>
            <div className='row'>
              <div className='col-md-12'>
                <ul className='nav nav-tabs project-group-types-tabs'>
                  {projectGroups.map((pm) => (
                    <li
                      className={`nav-item ${pm.id === activeProjectGroup.id ? 'active' : 'd-print-none'}`}
                      key={pm.id}
                      onClick={() => changeActiveProjectGroup(pm)}
                    >
                      <a className={`nav-link ${pm.id === activeProjectGroup.id ? 'active' : 'd-print-none'}`}>
                        {pm.project_group_type.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-md-12'>
                <div className='project-group-show-me-tabs ms-4 d-print-none'>
                  Show me:

                  <a
                    onClick={() => setViewType('project_tasks')}
                    className={`ms-4 ${viewType === 'project_tasks' ? 'active' : ''}`}
                  >
                    TASKS
                  </a>

                  <a
                    onClick={() => setViewType('files')}
                    className={`ms-4 ${viewType === 'files' ? 'active' : ''}`}
                  >
                    FILES
                  </a>
                </div>

                {viewType === 'project_tasks' && (
                  <Table
                    project_group={activeProjectGroup}
                    project_id={project_id}
                    auth={auth}
                  />
                )}

                {viewType === 'files' && (
                  <TasksFiles
                    resource_type={'project_groups'}
                    resource_link={'project_tasks'}
                    resource_id={activeProjectGroup.id}
                    auth={auth}
                  />
                )}
              </div>

              <div className='col-md-12'>
                {activeProjectGroup.id && (
                  <ShowGroupInfo project_group={activeProjectGroup} />
                )}
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
};

const ProjectsShow = ({ auth }) => {
  const navigate = useNavigate();
  const { project_id } = useParams();
  const location = useLocation();
  const [openInvoicing, setOpenInvoicing] = useState(false);
  const [openInventory, setOpenInventory] = useState(false);
  const { tab } = queryString.parse(location.search);

  const handleNavigate = (path) => {
    if (path) {
      navigate(path);
    } else {
      navigate(-1); // Equivalent to goBack()
    }
  };

  const reloadProject = () => {
    window.location.reload();
  };

  return (
    <div className='app pb-5 project-show'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <ShowHeader
              navigate={handleNavigate}
              project_id={project_id}
              auth={auth}
              reloadProject={reloadProject}
              openInvoices={() => setOpenInvoicing(true)}
              openInventory={() => setOpenInventory(true)}
            />
          </div>
        </div>
        {openInvoicing && (
          <div className='row mt-3'>
            <div className='col-md-12'>
              <Invoices
                project_id={project_id}
                close={() => setOpenInvoicing(false)}
              />
            </div>
          </div>
        )}
        {openInventory && (
          <div className='row mt-3'>
            <div className='col-md-12'>
              <div className='bg-white px-4 py-3 project-groups'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <div className="col-md-6">
                        <h4>Inventory</h4>
                      </div>
                      <div className='col-md-6'>
                        <a
                          style={{ fontSize: '24px', cursor: 'pointer' }}
                          onClick={() => setOpenInventory(false)}
                          className='pull-right'>
                          <i className='fa fa-times'></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <InventoryInOut
                      project_id={project_id}
                      sub_organization_user={auth.user.sub_organization_user}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='row mt-3'>
          <div className='col-md-12'>
            <ShowTabs
              tab={tab}
              project_id={project_id}
              navigate={navigate}
              auth={auth}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsShow;
