import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import api from '../../utils/pm_api'
import { TopBarPortal } from './helpers/admin_view'

import DefaultTask from './task_views/DefaultTask'
import MilestoneTask from './task_views/MilestoneTask'
import FormTask from './task_views/FormTask'
import ImporterTask from './task_views/ImporterTask'
import MultiFormReportTask from './task_views/MultiFormReportTask'
import OrderTask from './task_views/OrderTask'
import TaskGenerator from './task_views/TaskGenerator'

const queryString = require('query-string')

const ProjectTaskShow = ({ auth }) => {
  const [loading, setLoading] = useState(true);
  const [reloadComments, setReloadComments] = useState(false);
  const [projectTask, setProjectTask] = useState({
    project_task_type: {
      behaviours: []
    }
  });

  const { project_task_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigate = (path, forced) => {
    const params = queryString.parse(location.search);
    if (!forced && params.resource_type && params.resource_id) {
      path = `/projects_management/${params.resource_type}/${params.resource_id}?tab=${params.tab}`;
    }

    if (path) {
      navigate(path);
    } else {
      navigate(-1);
    }
  };

  const loadTask = (id) => {
    api.getProjectTask(id).then((response) => {
      document.title = `${response.data.attributes.project.name} - ${response.data.attributes.project_group.name} - ${response.data.attributes.name}`;
      setProjectTask(response.data.attributes);
      setLoading(false);
    });
  };

  const getTaskTypeComponent = (types) => {
    const classifierId = queryString.parse(location.search).classifier_id;
    let component = <div></div>;
    let type = 'task';

    if (types.includes('form')) {
      type = 'form';
    } else if (types.includes('task_generator')) {
      type = 'task_generator';
    } else if (types.includes('recurrent_form')) {
      type = 'recurrent_form';
    } else if (types.includes('task_importer')) {
      type = 'task_importer';
    } else if (types.includes('task_order_list')) {
      type = 'task_order_list';
    } else if (types.includes('parent')) {
      type = 'parent';
    }

    const commonProps = {
      navigate: handleNavigate,
      loading,
      project_task: projectTask,
      user: auth.user,
      updateProjectTask: (project_task) => {
        setProjectTask(project_task);
        setReloadComments(!reloadComments);
      },
      reload_comments: reloadComments,
    };

    switch (type) {
      case 'parent':
        component = <MilestoneTask {...commonProps} classifier_id={classifierId} />;
        break;
      case 'task_importer':
        component = <ImporterTask {...commonProps} />;
        break;
      case 'task_order_list':
        component = <OrderTask {...commonProps} />;
        break;
      case 'form':
        component = <FormTask {...commonProps} />;
        break;
      case 'task_generator':
        component = <TaskGenerator {...commonProps} />;
        break;
      case 'recurrent_form':
        component = projectTask.multi_form_project_task_id
          ? <FormTask {...commonProps} />
          : <MultiFormReportTask {...commonProps} />;
        break;
      default:
        component = <DefaultTask {...commonProps} />;
        break;
    }

    return component;
  };

  useEffect(() => {
    document.getElementsByClassName('sub-navbar')[0].style.display = 'none';
    loadTask(project_task_id);

    return () => {
      document.getElementsByClassName('sub-navbar')[0].style.display = 'block';
    };
  }, [project_task_id]);

  useEffect(() => {
    loadTask(project_task_id);
  }, [project_task_id]);

  const taskTypeComponent = getTaskTypeComponent(projectTask.project_task_type.behaviours);

  return (
    <div className='app pb-5 project-show'>
      {projectTask.id && <TopBarPortal type='project_task' record={projectTask} />}

      <div className='container'>
        {projectTask.id && <>{taskTypeComponent}</>}
      </div>
    </div>
  );
};

export default ProjectTaskShow;
