import React, { Component, Fragment, useContext } from 'react'
import { render } from 'react-dom'
import api from '../../utils/pm_api'

import project_task_api from '../../utils/pm_project_tasks_api'

import { Link } from 'react-router-dom'
import FileView from './common/FileView'

import Lightbox from 'react-image-lightbox'
import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.delete['X-CSRF-TOKEN'] = csrf_token

import { OrganizationContext } from '../../contexts/OrganizationContext'

const Actions = (props) => {
  let context =  useContext(OrganizationContext)
  let { organization, user} = context

  if(organization.allow_attachments_review_status && user.can_approve_attachments ) {
    return(
      <div className="dropdown show d-inline-block ms-2">
        <a className="btn btn-sm btn-outline-secondary" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className='fa fa-ellipsis-h'></i>
        </a>

        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
          <a className="dropdown-item cursor-pointer" onClick={() => props.handleAction('approve_all')}>Approve All</a>
          <a className="dropdown-item cursor-pointer" onClick={() => props.handleAction('disapprove_all')} >Disapprove All</a>
        </div>
      </div>
    )
  } else {
    return(<Fragment></Fragment>)
  }
}


class SideActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      timeFetching: 0,
      attachment: {},
    }

    this.exportImages = this.exportImages.bind(this)

    this.getPollingInteral = this.getPollingInterval(this);
    this.reloadButton = this.reloadButton.bind(this);
  }

  exportImages() {
    this.setState({loading: true}, () => {
      api.createProjectGroupReport(this.props.resource_id, {report: 'images_report', filters: this.props.filters}).
        then((response) => {
          this.setState({attachment: response.data.attributes}, () => {
            this.reloadButton()
          })
        })
    })
  }

  getPollingInterval() {
    let timeFetching = this.state.timeFetching
    if(timeFetching >= 0 && timeFetching < 20000) {
      return 5000
    } else if(timeFetching >= 20000 && timeFetching < 60000) {
      return 10000
    } else if(timeFetching >= 60000 && timeFetching < 120000) {
      return 20000
    } else if(timeFetching >= 120000 && timeFetching < 300000) {
      return 30000
    } else {
      return 30000
    }
  }

  reloadButton() {
    api.getProjectGroupAttachment(this.props.resource_id, this.state.attachment.id).
      then((response) => {
        let attachment = response.data.attributes
        clearInterval(this.state.reloadInterval)
        if (!attachment.file_url) {
          let interval = this.getPollingInterval()
          let timeFetching = this.state.timeFetching + interval

          this.setState(() => {
            return {
              timeFetching: timeFetching,
              reloadInterval: setInterval(this.reloadButton, interval)
            }
          })
        } else {
          this.setState({attachment: attachment, loading: false}, () => {
            window.open(attachment.file_url, '_blank');
          })
        }
      })
  }

  render() {
    let { loading, attachment } = this.state
    let text = loading ? 'Processing ...' : 'Export PDF images'

    return(
      <div className='pull-right'>
        { attachment.file_url ? (
          <a className='btn btn-sm btn-outline-secondary' target="_black" href={attachment.file_url} download>
            Download generated PDF
          </a>
        ) : (
          <button disabled={loading} className='btn btn-sm btn-outline-secondary' onClick={this.exportImages}>
            {text}
          </button>
        )}

        <Actions
          handleAction={this.props.handleAction}
        />
      </div>
    )
  }
}

export default class TasksFiles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      groups: [],
      images: [],
      isOpen: false,
      photoIndex: 0,
      filters: [],
      files: [],
    }

    this.loadFiles = this.loadFiles.bind(this)
    this.handleFilters = this.handleFilters.bind(this)
    this.handleAction = this.handleAction.bind(this)
  }

  handleFilters(filter) {
    let filters = this.state.filters || []

    if(filters.includes(filter)) {
      const index = filters.indexOf(filter)
      filters.splice(index, 1)
    } else {
      filters.push(filter)
    }

    this.setState({filters}, () => {
      this.loadFiles(this.props.resource_id, filters)
    })
  }

  handleAction(type) {
    api.changeFilesState(this.props.resource_type, this.props.resource_id, this.state.filters, type).then((response) => {
      this.setState({groups: [], loading: true}, () => {
        this.loadFiles(this.props.resource_id, this.state.filters)
      })
    })
  }

  loadFiles(id, filters) {
    api.getFiles(this.props.resource_type, id, filters).then((response) => {
      let groups = []
      let files = []
      let images = []

      if(filters.includes('show_ungrouped')) {
        let tasks_map = {}
        response.tasks.forEach((task) => {
          tasks_map[task.id] = task.name
        })

        response.files.data.forEach((file) => {
          let att = file.attributes
          att.show_task_name = tasks_map[att.resource_id]
          files.push(att)
        })

        response.project_task_attachments.data.forEach((file) => {
          let att = file.attributes
          att.show_task_name = tasks_map[att.project_task_id]
          att.type = 'pta'
          files.push(att)
        })

        let image_index = 0
        files.forEach((file) => {
          let is_image = ['png','jpg','jpeg','gif','heic'].includes((file.extension || "").toLowerCase())
          if(is_image) {
            file.index = image_index
            file.task_name = file.show_task_name,
            image_index = image_index + 1
            images.push(file)
          }
        })

        files.sort((a ,b) => {
          if(filters.includes('oldest_first')) {
            return a.created_at_to_i - b.created_at_to_i
          } else {
            return b.created_at_to_i - a.created_at_to_i
          }
        })

      } else {
        response.tasks.forEach((task) => {
          let new_files = []

          let show_name = task.project_task_importer_job_id ? task.name : (task.project_task_type.name || task.name)
          task.show_name = show_name

          response.project_task_attachments.data.forEach((file) => {
            if(file.attributes.project_task_id == task.id) {
              let att = file.attributes
              att.type = 'pta'
              new_files.push(att)
            }
          })

          response.files.data.forEach((file) => {
            if(file.attributes.resource_id == task.id) {
              new_files.push(file.attributes)
            }
          })

          groups.push({
            task: task,
            files: new_files
          })
        })

        let image_index = 0

        groups.forEach((group) => {
          group.files.forEach((file) => {
            let is_image = ['png','jpg','jpeg','gif','heic'].includes((file.extension || "").toLowerCase())
            if(is_image) {
              file.index = image_index
              file.task_name = group.task.show_name,
              image_index = image_index + 1
              images.push(file)
            }
          })
        })
      }

      this.setState({
        loading: false,
        groups: groups,
        images: images,
        files: files,
      })
    })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.resource_id != this.props.resource_id) {
      this.setState({groups: [], loading: true}, () => {
        this.loadFiles(this.props.resource_id, this.state.filters)
      })
    }
  }

  componentWillMount() {
    this.loadFiles(this.props.resource_id, this.state.filters)
  }

  removeFile = (file) => {
    if(file.type == 'attachment') {
      let attachment_id = file.id

      axios.delete(`/web_api/projects_management/attachments/${attachment_id}`).then(() => {
        this.loadFiles(this.props.resource_id, this.state.filters)
      })
    } else {
      project_task_api.removeProjectTaskAttachment(file.project_task_id, file.id).then((response) => {
        this.loadFiles(this.props.resource_id, this.state.filters)
      })
    }
  }

  render() {
    let { isOpen, images, photoIndex, filters } = this.state
    let new_images = []

    let titles = []
    images.forEach((f) => {
      if(f.processed_image) {
        new_images.push(f.extra_url.optimized)
      } else {
        new_images.push(f.file_url)
      }
      titles.push(<p className='mt-2'>{f.task_name} - {f.name}</p>)
    })

    images = new_images
    let not_grouped = filters.includes('show_ungrouped')

    return(
      <div className='files-map px-4 pb-4'>
        { this.state.loading ? (
          <center> Loading ...</center>
        ) : (
          <div>
            <div className='row mt-1'>
              <div className='col-md-12 pt-1'>
                <div className='text-info small'>
                  <span className={'me-4 cursor-pointer ' + (filters.includes('images_only') ? 'fw-bold' : '')} onClick={() => this.handleFilters('images_only')}>
                    Images Only
                  </span>
                  <span className={'me-4 cursor-pointer ' + (filters.includes('oldest_first') ? 'fw-bold' : '')} onClick={() => this.handleFilters('oldest_first')}>
                    Oldest First
                  </span>
                  <span className={'me-4 cursor-pointer ' + (filters.includes('show_empty') ? 'fw-bold' : '')} onClick={() => this.handleFilters('show_empty')}>
                    Show Empty
                  </span>
                  <span className={'me-4 cursor-pointer ' + (filters.includes('show_ungrouped') ? 'fw-bold' : '')} onClick={() => this.handleFilters('show_ungrouped')}>
                    Show Ungrouped
                  </span>
                  { (this.props.auth.organization.can_delete_attachment || this.props.auth.organization.can_delete_project_task_attachment) &&
                    <span className={'me-4 cursor-pointer ' + (this.state.manager_mode ? 'fw-bold' : '')} onClick={() => this.setState({manager_mode: !this.state.manager_mode})}>
                      Manager Mode
                    </span>
                  }
                </div>
              </div>
              { this.state.groups.length > 0 &&
                <div className='col-md-12'>
                  <SideActions {...this.props} filters={filters} handleAction={this.handleAction}/>
                </div>
              }
            </div>

            { not_grouped ? (
              <Fragment>
                {this.state.files.length == 0 ? (
                  <p className='text-secondary text-center my-5'>
                    No files have been added
                  </p>
                ) : (
                  <div className='body mt-4'>
                    <div className='row'>
                      {this.state.files.map((file, index) => {
                        return(
                          <FileView
                            key={file.id}
                            file={file}
                            onImageClick={() => this.setState({isOpen: true, photoIndex: file.index})}
                            can_delete_attachment={this.state.manager_mode && this.props.auth.organization.can_delete_attachment}
                            can_delete_project_task_attachment={this.state.manager_mode && this.props.auth.organization.can_delete_project_task_attachment}
                            remove_file={this.removeFile}
                          />
                        )
                      })}
                    </div>
                  </div>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {this.state.groups.length == 0 &&
                  <p className='text-secondary text-center my-5'>
                    No files have been added
                  </p>
                }

                {this.state.groups.map((group, index) => {
                  let {task, files} = group
                  let name = task.show_name
                  let classifier_a = task.classifier_a.name
                  let has_attachment_groups = task.project_task_type.attachment_groups.length > 0
                  let attachment_groups = []

                  if (has_attachment_groups) {
                    let used_identifiers = []

                    task.project_task_type.attachment_groups.forEach((attachment_group) => {
                      let a_files = files.filter(e => e.classifier_a == attachment_group.identifier)
                      used_identifiers.push(attachment_group.identifier)

                      attachment_groups.push({
                        label: attachment_group.label,
                        files: a_files,
                      })
                    })
                    let general_files = files.filter(e => !used_identifiers.includes(e.classifier_a))

                    attachment_groups.push({
                      label: 'General',
                      files: general_files,
                    })
                  }

                  return(
                    <div className='row mt-4' key={index}>
                      <div className='col-md-12'>
                        <div className='header'>
                          <h5 className='d-inline'>
                            {name}
                          </h5>
                          <div className='d-inline pull-right d-print-none'>
                            { false && <span className='ms-2 badge badge-warning'>{task.pending_notes} Notes Pending</span> }
                            <Link className='ms-2' to={`/projects_management/${this.props.resource_link}/` + task.id}>Details</Link>
                          </div>
                        </div>

                        { classifier_a &&
                          <h6 className="mt-3 text-uppercase small fw-bold text-secondary">
                            {classifier_a}
                          </h6>
                        }

                        { has_attachment_groups ? (
                          <Fragment>
                            {attachment_groups.map((group, i) => {
                              let classname = classifier_a ? "ps-2" : ""

                              return(
                                <div className={classname} key={i}>
                                  <h6 className="mt-3 text-uppercase small fw-bold text-secondary">
                                    {group.label}
                                  </h6>

                                  <div className='body'>
                                    { group.files.length > 0 ? (
                                      <div className='row'>
                                        {group.files.map((file, index) => {
                                          return(
                                            <FileView
                                              key={file.id}
                                              file={file}
                                              onImageClick={() => this.setState({isOpen: true, photoIndex: file.index})}
                                              remove_file={this.removeFile}
                                              can_delete_attachment={this.state.manager_mode && this.props.auth.organization.can_delete_attachment}
                                              can_delete_project_task_attachment={this.state.manager_mode && this.props.auth.organization.can_delete_project_task_attachment}
                                            />
                                          )
                                        })}
                                      </div>
                                    ) : (
                                      <div className='text-secondary small text-center' style={{marginTop: '20px'}}>
                                        No files
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                            })}

                          </Fragment>
                        ) : (
                          <div className='body'>
                            { group.files.length > 0 ? (
                              <div className='row'>
                                {group.files.map((file, index) => {
                                  return(
                                    <FileView
                                      key={file.id}
                                      file={file}
                                      onImageClick={() => this.setState({isOpen: true, photoIndex: file.index})}
                                      remove_file={this.removeFile}
                                      can_delete_attachment={this.state.manager_mode && this.props.auth.organization.can_delete_attachment}
                                      can_delete_project_task_attachment={this.state.manager_mode && this.props.auth.organization.can_delete_project_task_attachment}
                                    />
                                  )
                                })}
                              </div>
                            ) : (
                              <div className='text-secondary small text-center' style={{marginTop: '20px'}}>
                                No files
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })}
              </Fragment>
            )}
         </div>
        )}

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
            imageTitle={titles[photoIndex]}
          />
        )}

      </div>
    )
  }
}
