import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import MapContainer from '../maps/MapContainer';
import api from '../../../utils/pm_boards_api';

const ProjectQueueMap = ({ sub_organization_filter, auth }) => {
  const { project_board_id, project_queue_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [queue, setQueue] = useState(undefined);

  const handleBack = (lists, go_to_queue) => {
    const has_base = location.search.match(/base/);

    if (lists) {
      if (has_base && !go_to_queue) {
        navigate(`/projects_management/project_boards`);
      } else {
        navigate(`/projects_management/project_boards/${project_board_id}/project_queues/${project_queue_id}?lists=true`);
      }
    } else {
      if (has_base && !go_to_queue) {
        document.getElementsByClassName('sub-navbar')[0].style.display = 'block';
        navigate(`/projects_management/project_boards/${project_board_id}`);
      } else {
        navigate(`/projects_management/project_boards/${project_board_id}/project_queues/${project_queue_id}`);
      }
    }
  };

  useEffect(() => {
    api.getQueue(project_board_id, project_queue_id, {
      include_extra_column_values: true,
      sub_organization_filter,
    }).then((response) => {
      setQueue(response.data.attributes);
    });
  }, [project_board_id, project_queue_id, sub_organization_filter]);

  return (
    <div className='app p-0'>
      {queue && (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <MapContainer
                record={queue}
                handleBack={handleBack}
                type="queue"
                map_filters={queue.additional_columns}
                sub_organization_filter={sub_organization_filter}
                auth={auth}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectQueueMap;
