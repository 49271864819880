import React, { Component } from 'react'
import { render } from 'react-dom'

import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

import SubOrganizationsIndex from '../components/projects_management/sub_organizations/SubOrganizationsIndex'
import SubOrganizationsShow from '../components/projects_management/sub_organizations/SubOrganizationsShow'
import SubOrganizationsProfileTaskShow from '../components/projects_management/sub_organizations/SubOrganizationsProfileTaskShow'

export default class PmSubOrganizationsContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Router>
        <Routes>
          <Route
            path="/projects_management/sub_organizations"
            element={<SubOrganizationsIndex auth={this.props.auth} />}
          />
          <Route
            path="/projects_management/sub_organizations/:id"
            element={<SubOrganizationsShow auth={this.props.auth} />}
          />
          <Route
            path="/projects_management/sub_organization_profile_tasks/:task_id"
            element={<SubOrganizationsProfileTaskShow auth={this.props.auth} />}
          />
        </Routes>
      </Router>
    )
  }
}
