import React, { Component } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { OrganizationContext } from "../contexts/OrganizationContext";
import TimesheetIndex from "../components/projects_management/timesheet/TimesheetIndex";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient();

const PmTimesheetContainer = ({ auth, context_data }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <OrganizationContext.Provider value={context_data}>
          <Routes>
            <Route
              path="/projects_management/timesheet"
              element={<TimesheetIndex user={auth.user} />}
            />
            <Route
              path="/projects_management/timesheet_management"
              element={<TimesheetIndex user={auth.user} />}
            />
          </Routes>
        </OrganizationContext.Provider>
      </Router>
    </QueryClientProvider>
  );
};

export default PmTimesheetContainer;
