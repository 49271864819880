import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import IndexProjectsTable from './index_tables/IndexProjectsTable';
import IndexProjectGroupsTable from './index_tables/IndexProjectGroupsTable';
import IndexProjectTasksTable from './index_tables/IndexProjectTasksTable';
import IndexProjectInvoicesTable from './index_tables/IndexProjectInvoicesTable';

const ProjectsIndex = (props) => {
  const [viewType, setViewType] = useState('projects');
  const navigate = useNavigate();

  const showComponent = () => {
    switch(viewType) {
      case 'project_groups':
        return <IndexProjectGroupsTable />;
      case 'project_tasks':
        return <IndexProjectTasksTable navigate={navigate} auth={props.auth.project_tasks} user={props.auth.user} />;
      case 'project_invoices':
        return <IndexProjectInvoicesTable navigate={navigate} auth={props.auth.project_tasks} user={props.auth.user} />;
      default:
        return <IndexProjectsTable auth={props.auth} />;
    }
  };

  useEffect(() => {
    document.getElementsByClassName('sub-navbar')[0].style.display = 'block';
  }, []);

  const canListInvoices = props.auth.projects.can_list_invoices;

  return (
    <div className='app pb-5'>
      <div className='container-fluid py-4'>
        <div className='bg-white p-4'>
          <div className='row pb-4'>
            <div className='col-md-12'>
              <ul className="nav nav-tabs pm-tabs">
                <li className="nav-item">
                  <a
                    className={`nav-link ${viewType === 'projects' ? 'active' : ''}`}
                    href="#"
                    onClick={() => setViewType('projects')}
                  >
                    Projects
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${viewType === 'project_tasks' ? 'active' : ''}`}
                    href="#"
                    onClick={() => setViewType('project_tasks')}
                  >
                    Tasks
                  </a>
                </li>
                {canListInvoices && (
                  <li className="nav-item">
                    <a
                      className={`nav-link ${viewType === 'project_invoices' ? 'active' : ''}`}
                      href="#"
                      onClick={() => setViewType('project_invoices')}
                    >
                      Invoices
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              {showComponent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsIndex;
