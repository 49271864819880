import React, { useState } from "react";
import UpdateDateEntriesModal from "./UpdateDateEntriesModal";
import TimeInput from "./TimeInput";
import WorkTypeSelectModal from "./WorkTypeSelectModal";

const IntervalGroup = ({
  report_label,
  users,
  handleDirection,
  newUsers,
  updateNewMinutes,
  handleNewCancel,
  handleNewSave,
  editableTimesheetLine,
  handleTimesheetLine,
  handleTimesheetLineCancel,
  updateTimesheetMinutes,
  handleTimesheetSave,
  datasources,
}) => {
  const [selectedRange, setSelectedRange] = useState(null);
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);

  const handleSelect = (user, date) => {
    setSelectedRange({ user, date });
  };

  const handleWeek = (direction) => {
    handleDirection(direction);
  };

  const handleEmptyCell = (user) => {
    let default_work_type_id = user.default_work_type_id;

    if (default_work_type_id) {
      let default_work_type = datasources.work_types.find(
        (e) => e.value === default_work_type_id
      );
      if (default_work_type) {
        handleTimesheetLine(user, { workType: default_work_type });
      } else {
        setSelectedTimesheet(user);
      }
    } else {
      setSelectedTimesheet(user);
    }
  };

  const handleSelectWorkType = (workType) => {
    handleTimesheetLine(selectedTimesheet, workType);
    setSelectedTimesheet(null);
  };

  return (
    <div className="row">
      <div className="col-12 table-responsive">
        <table className="table table-sm mb-0 mt-2 no-border-table table-striped">
          <thead>
            <tr>
              <th
                scope="col"
                style={{
                  width: "auto",
                  minWidth: "200px",
                  borderBottom: "1px !important",
                }}
              >
                <i
                  className="fa fa-chevron-left pe-3 cursor-pointer"
                  onClick={() => handleWeek("back")}
                ></i>
                {report_label}
                <i
                  className="fa fa-chevron-right ps-3 cursor-pointer"
                  onClick={() => handleWeek("next")}
                ></i>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Mon</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Tue</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Wed</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Thu</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Fri</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Sat</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Sun</center>
              </th>
              <th scope="col" style={{ width: "100px", minWidth: "90px" }}>
                <center>Sub total</center>
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => {
              return (
                <tr key={index}>
                  <td className="d-flex justify-content-between align-items-center">
                    <div>{user.name}</div>
                    {editableTimesheetLine.user_id === user.user_id && (
                      <div className="d-flex pull-right">
                        <button
                          className="btn btn-sm btn-light me-1"
                          onClick={() => handleTimesheetLineCancel()}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => handleTimesheetSave(user.user_id)}
                          disabled={editableTimesheetLine.minutes.every(
                            (m) => m === 0
                          )}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </td>

                  {editableTimesheetLine.user_id === user.user_id ? (
                    <>
                      {user.hours.map((hour, hourIndex) => (
                        <td key={hourIndex} className="">
                          <center
                            className={`${
                              hour !== "0h"
                                ? "text-primary cursor-pointer my-1"
                                : ""
                            }`}
                            style={{ fontSize: "14px" }}
                            onClick={() => {
                              if (hour !== "0h") {
                                handleSelect(user, user.dates[hourIndex]);
                              }
                            }}
                          >
                            {hour !== "0h" ? (
                              <>{hour}</>
                            ) : (
                              <>
                                <TimeInput
                                  minutes={
                                    editableTimesheetLine.minutes[hourIndex]
                                  }
                                  updateMinutes={(newMinutes) =>
                                    updateTimesheetMinutes(
                                      hourIndex,
                                      newMinutes
                                    )
                                  }
                                />
                              </>
                            )}
                          </center>
                        </td>
                      ))}
                    </>
                  ) : (
                    <>
                      {user.hours.map((hour, hourIndex) => (
                        <td key={hourIndex}>
                          <center
                            className={`${
                              hour !== "0h"
                                ? "text-primary cursor-pointer"
                                : "text-secondary cursor-pointer"
                            }`}
                            style={{ fontSize: "14px" }}
                            onClick={() => {
                              if (hour !== "0h") {
                                handleSelect(user, user.dates[hourIndex]);
                              } else {
                                handleEmptyCell(user);
                              }
                            }}
                          >
                            {hour || 0}
                          </center>
                        </td>
                      ))}
                    </>
                  )}

                  <td>
                    <center>{user.sub_total} </center>
                  </td>
                </tr>
              );
            })}
            <></>
            {newUsers.map((user, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>{user.name}</div>
                      <div className="d-flex pull-right">
                        <button
                          className="btn btn-sm btn-light me-1"
                          onClick={() => handleNewCancel(user.user_id)}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => handleNewSave(user.user_id)}
                          disabled={user.minutes.every((m) => m === 0)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </td>
                  {user.minutes.map((minutes, mIndex) => (
                    <td key={mIndex}>
                      <TimeInput
                        minutes={minutes}
                        updateMinutes={(newMinutes) =>
                          updateNewMinutes(user.user_id, mIndex, newMinutes)
                        }
                      />
                    </td>
                  ))}
                  <td>
                    <center> - </center>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {selectedRange && (
        <UpdateDateEntriesModal
          user={selectedRange.user}
          date={selectedRange.date}
          onClose={() => setSelectedRange(null)}
        />
      )}

      {selectedTimesheet && (
        <WorkTypeSelectModal
          onClose={() => setSelectedTimesheet(null)}
          onSubmit={handleSelectWorkType}
          work_types={datasources.work_types}
        />
      )}
    </div>
  );
};

export default IntervalGroup;
