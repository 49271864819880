import React, { useState } from "react";
import ProjectTaskLabel from "../ProjectTaskLabel";
import ActivityEntry from '../ActivityEntry';

function groupByProjectTaskId(arr) {
  const groupedMap = arr.reduce((acc, item) => {
    const key = item.project_task_id;

    if (!acc.has(key)) {
      acc.set(key, []);
    }

    acc.get(key).push(item);

    return acc;
  }, new Map());

  return Array.from(groupedMap.values());
}

const ProjectTasksWorkflowExecuted = ({entry, from_group, data: original_data, mode}) => {
  let { data, project_task } = entry
  let { workflow } = data
  const [isOpen, setIsOpen] = useState(false);

  let sub_group = []

  if(mode == 'project') {
    sub_group = groupByProjectTaskId(original_data.filter((e) => e.project_workflow_id == data.project_workflow_id && entry.request_id == e.request_id && e.event != 'ProjectTasks::Events::WorkflowExecuted'))
  }

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className="timeline-item mb-4">
       <div className="d-flex align-items-center mb-1">
        {project_task && !from_group && (
          <ProjectTaskLabel project_task={project_task} />
        )}
        <h6 className="mb-0">
          {workflow} <span className="small">workflow started {sub_group.length > 0 && (
            <strong className="cursor-pointer" onClick={handleToggle}>and has affected {sub_group.length} {sub_group.length == 1 ? "task": "tasks"} </strong>
          )} </span>
        </h6>

        {/* Chevron para toggle */}
        {sub_group.length > 0 && (
          <i
            className={`fa ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'} ms-2`}
            onClick={handleToggle}
            style={{ cursor: 'pointer', marginLeft: 'auto' }}
          ></i>
        )}
      </div>

      {!from_group && (
        <small className="text-secondary mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}

      {/* Sección desplegable en una línea separada */}
      {isOpen && (
        <div className="pt-4 pb-2 px-4" style={{backgroundColor: '#f6fdff'}}>
          <ul className="timeline-basic-example">
             {sub_group.map((group, v) => {
               return (
                 <ActivityEntry group={group} key={v} />
               );
             })}
          </ul>
        </div>
      )}
    </li>
  );
}

export default ProjectTasksWorkflowExecuted
