import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import ReactTable from "react-table"
import api from '../../../utils/pm_dashboards_api'

import DebounceInput from 'react-debounce-input';
import { DateRangePicker } from 'react-dates'
import ExportTableButton from './ExportTableButton'

const headersTable = (extra_columns) => {
  let headers = ["Case"]

  extra_columns.forEach((column) => {
    headers.push(column.label)
  })

  return headers
}

const serializeDateFilters = (dateFilters) => {
  const serializedFilters = {};
  Object.keys(dateFilters).forEach(key => {
    const value = dateFilters[key];
    serializedFilters[key] = value ? value.toISOString() : null;
  });
  return serializedFilters;
}

const exportRows = (extra_columns, data) => {
  let rows = []
  data.forEach((record) => {
    let base_row = [record.name]
    extra_columns.forEach((column) => {
      base_row.push(record.extra_columns[column.key])
    })
    rows.push(base_row)
  })

  return rows
}

export default class ConfigurableTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      pages: null,
      loading: true,
      date_filters: {},
      total_count: 0
    }

    this.columns = this.columns.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  fetchData(state, instance, maintain_selection) {
    let { resource_id, resource_type } = this.props
    let selection = maintain_selection ? this.state.selection : []
    this.setState({
      selection: selection,
      page: state.page,
      per_page: state.pageSize,
      loading: true,
      filtered: state.filtered,
      sorted: state.sorted,
      open_modal: false,
    }, () => {
      let filters = {
        ...this.state.filters,
        per_page: state.pageSize || 20,
        page: state.page,
        filtered: state.filtered,
        sorted: state.sorted,
        date_filters: serializeDateFilters(this.state.date_filters)
      };
      api.getDashboardData(resource_id, filters).then((data) => {
        let records = (data.data || []).map((record) => record.attributes)
        let pages = data.meta.total_pages
        let total_count = data.meta.total_count

        this.setState({data: records, pages: pages, total_count: total_count, loading: false})
      })
    })
  }

  columns() {
    let columns = []
    let { extra_columns, config } = this.props

    if(config.datasource == 'projects') {
      columns = [
        {
          Header: () => (
            <span title="">Case</span>
          ),
          accessor: 'name',
          width: 100,
          style: { 'whiteSpace': 'unset' },
          Cell: row => (
            <Fragment>
                <a target="_blank" href={"/projects_management/projects/" + row.original.id}  className="text-info">
                  {row.original.name}
                </a>
            </Fragment>
          ),
          Filter: ({ filter, onChange }) => (
            <DebounceInput
            value={filter ? filter.value : ''}
            name='q-name'
            placeholder='All'
            type='search'
            className="form-control form-control-sm"
            autoComplete='off'
            minLength={3}
            debounceTimeout={500}
            onChange={event => onChange(event.target.value)}
            aria-describedby="basic-addon2" />
          )
        }
      ]
    }

    extra_columns.forEach((column) => {
      let filterable = true
      let sortable = true
      let minLength = 3

      if(column.filter_type == 'date' || column.filter_type == 'timestamp') {
        filterable = true
      }

      if(column.filter_type == 'integer') {
        filterable = false
        minLength = 1
      }

      let new_column = {
        Header: () => (
          <span title={column.description}>{column.label}</span>
        ),
        accessor: column.key,
        filterable: filterable,
        sortable: sortable,
        style: {
          whiteSpace: 'unset'
        },
        Cell: ({original}) => {
          if (original.extra_columns[column.key] && typeof original.extra_columns[column.key] === 'object') {
            return (
              <div className="text-center">
                {original.extra_columns[column.key].label || original.extra_columns[column.key].name }
              </div>
            );
          }


          return(
            <div className="text-center">
              {original.extra_columns[column.key]}
            </div>
          )
        },
        Filter: ({ filter, onChange }) => {
          if(column.filter_type == "date" || column.filter_type == 'timestamp') {
            let key = column.key
            let focus_key = key + "focused_input"
            let start_date_key = "start_date_"+key
            let end_date_key = "end_date_"+key
            let start_date = this.state.date_filters[start_date_key]
            let end_date = this.state.date_filters[end_date_key]
            return(
              <DateRangePicker
                startDate={start_date}
                startDateId={start_date_key}
                endDate={end_date}
                endDateId={end_date_key}
                onDatesChange={({ startDate, endDate }) => {
                  let date_filters = this.state.date_filters
                  date_filters[start_date_key] = startDate
                  date_filters[end_date_key] = endDate
                  this.setState({date_filters}, () => {
                    this.fetchData(this.state)
                  })
                }}
                focusedInput={this.state[focus_key]}
                onFocusChange={focusedInput => {
                  let new_state = {}
                  new_state[focus_key] = focusedInput
                  this.setState(new_state)
                }}
                isOutsideRange={() => false}
                withPortal={true}
                showClearDates={true}
              />
            )
          } else {
            return (
              <DebounceInput
                value={filter ? filter.value : ''}
                name={'q-' + column.key}
                placeholder='All'
                type='search'
                className="form-control form-control-sm"
                autoComplete='off'
                minLength={minLength}
                debounceTimeout={400}
                onChange={event => onChange(event.target.value)}
              />
            )
          }
        }
      }

      if(column.filter_type == 'date' || column.filter_type == 'timestamp') {
        new_column['width'] = 265
      }

      if(column.filter_type == 'integer') {
        new_column['maxWidth'] = 100
      }

      columns.push(new_column)
    })

    return columns
  }

  render() {
    let { data, pages, loading, open_modal, total_count } = this.state
    let { extra_columns, config } = this.props
    let sort = {id: "name", desc: false}
    let headers = headersTable(extra_columns)
    let export_rows = exportRows(extra_columns, data)


    if(config.default_sort.column) {
      sort.id = config.default_sort.column
    }

    if(config.default_sort.direction) {
      if(config.default_sort.direction == 'desc') {
        sort.desc = true
      }
    }

    return(
      <div>
        <div className='row align-items-end mb-2'>
          <div className='col text-secondary small'>
            {total_count} Records Found
          </div>
          <div className='col-6'>
            <div className='text-center'>
              <h5 className='mb-0'>
                {config.name}
              </h5>
              <small className='text-secondary'>
                {config.description}
              </small>
            </div>

          </div>

          <div className='col'>
            <div className='pull-right'>
              <ExportTableButton
                export_rows={export_rows}
                headers={headers}
                filename={config.name}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 small table-with-range-picker'>
            <ReactTable
              className="-striped -highlight"
              data={data}
              columns={this.columns()}
              defaultPageSize={20}
              manual
              filterable
              pages={pages}
              loading={loading}
              onFetchData={this.fetchData}
              defaultSorted={[sort]}
              minRows={2}
            />
          </div>
        </div>
      </div>
    )
  }
}
