import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import axios from 'axios'

import { ImagesLoader } from '../projects_management/helpers/Loaders'

import AttachmentFiles from '../projects_management/helpers/AttachmentFiles'
import Dropzone from 'react-dropzone'
import uuid from 'node-uuid'

export default class FilesPlaceholder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      files: [],
      max_files: 100,
    }

    this.onDrop = this.onDrop.bind(this)
  }

  componentDidMount() {
    let { resource_type, resource_id, file_key, parent_key, item_group } = this.props
    let url = `/web_api/projects_management/${resource_type}/${resource_id}/images`
    axios.get(url, {params: {image_key: file_key, parent_key, item_group, all_files: true}}).then((response) => {
      this.setState({loading: false, files: response.data.images}, () => {
        let files = response.data.images
        if(files && files.length > 0) {
          this.props.updateInputValue(files[0].name, true)
        }
      })
    })
  }

  onDrop(files) {
    let current_files = this.state.files
    let { resource_type, resource_id, item_group, parent_key } = this.props

    if((files.length + current_files.length) <= this.state.max_files) {
      let files_map = files.map((file) => {
        return Object.assign(
          file,
          {
            preview: URL.createObjectURL(file),
            uploaded: false,
            uuid: uuid.v4(),
            extension: file.name.split('.').pop(),
            key: this.props.file_key,
          }
        )
      })

      let files_data = files_map.map(file => {
        return {name: file.name, uuid: file.uuid, extension: file.extension, type: file.type }
      })

      let params = {
        resource_type: resource_type,
        resource_id: resource_id,
        files: files_data
      }

      axios.get(`/web_api/projects_management/${resource_type}/${resource_id}/signed_urls`, {params: params}).then((response) => {
        let data = response.data.signed_urls

        files_map = files_map.map((file) => {
          let upload_data = data[file.uuid]

          upload_data.item_group = item_group
          upload_data.parent_key = parent_key
          return Object.assign(file, {...upload_data})
        })

        this.setState({files: current_files.concat(files_map)})
      })
    }
  }

  render() {
    return(
      <Fragment>
        { this.state.loading ? (
          <ImagesLoader />
        ) : (
          <Fragment>
            { this.props.view == 'edit' &&
              <div className='row'>
                <div className='col-md-12'>
                  <Dropzone
                    className="dropzone py-4 mb-2"
                    multiple={true}
                    onDrop={this.onDrop}>
                    <center className="text-secondary"><u><span className="fw-bold">Choose files</span></u> or drag them here</center>
                  </Dropzone>
                </div>
              </div>
            }
            <div className='row'>
              <div className='col-md-12'>
                <AttachmentFiles
                  files={this.state.files}
                  resource_type={this.props.resource_type}
                  resource_id={this.props.resource_id}
                  comes_from_campodata_form={true}
                  updateInputValue={this.props.updateInputValue}
                  field_type="file"
                />
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    )
  }
}
