import React from "react";
import ProjectTaskLabel from "../ProjectTaskLabel";

const ProjectTasksSubmissionDataUpdated = ({entry, from_group}) => {
  let { data, current_user, project_task } = entry
  let { attachment } = data

  return (
    <li className="timeline-item mb-4">
      { project_task && !from_group &&
        <ProjectTaskLabel project_task={project_task}/>
      }

      <h6 className="mb-1 small">
        form data updated
      </h6>

      {!from_group && (
        <small className="text-secondary mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksSubmissionDataUpdated
