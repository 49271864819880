import React, { Fragment } from "react"

export const TableHeaders = ({job}) => {
  let table_settings = job.table_settings || {}

  return (
    <thead>
      <tr className="small text-secondary">
        <th scope="col" colSpan="2">Name</th>
        {table_settings.extra_column_label && (
          <th scope="col">{table_settings.extra_column_label}</th>
        )}
        <th scope="col">Qty</th>
        <th scope="col">Unit Cost</th>
        {job.show_taxes && (
          <Fragment>
            <th scope="col">Sub Total</th>
            <th scope="col">Tax</th>
          </Fragment>
        )}
        <th scope="col">Item Cost</th>
      </tr>
    </thead>
  );
}
