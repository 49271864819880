import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import TaskOrderShowHeader from './TaskOrderShowHeader';
import InvoiceableTable from './InvoiceableTable';

import api from '../../../../utils/pm_api';
import tasks_api from '../../../../utils/pm_project_tasks_api';

const TaskOrderShow = (props) => {
  const [projectTask, setProjectTask] = useState({});
  const [taskOrder, setTaskOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeOrder, setActiveOrder] = useState(null);
  const navigate = useNavigate();
  const { project_task_id, task_order_id } = useParams();

  const changeActiveOrder = (e) => {
    setActiveOrder(e);
  };

  useEffect(() => {
    // Hide the sub-navbar on mount
    document.getElementsByClassName('sub-navbar')[0].style.display = 'none';

    // Fetch project task and task order data
    api.getProjectTask(project_task_id).then((response) => {
      const projectTaskData = response.data.attributes;
      tasks_api.getTaskOrder(project_task_id, task_order_id).then((to_response) => {
        const taskOrderData = to_response.data;
        const activeOrderData = taskOrderData.past_orders.find((e) => e.value === task_order_id);

        setProjectTask(projectTaskData);
        setTaskOrder(taskOrderData);
        setActiveOrder(activeOrderData);
        setLoading(false);
      });
    });
  }, [project_task_id, task_order_id]);

  const handleNavigate = (path) => {
    if (path) {
      navigate(path);
    } else {
      navigate(-1); // Go back if no path is provided
    }
  };

  const { user } = props.auth;

  return (
    <div className='app pb-5 project-show'>
      <div className='container'>
        {!loading && (
          <div className='bg-white'>
            <div className='row'>
              <div className='col-md-12'>
                <TaskOrderShowHeader
                  navigate={handleNavigate}
                  loading={loading}
                  project_task={projectTask}
                  task_order={taskOrder}
                  user={user}
                  active_order={activeOrder}
                  changeActiveOrder={changeActiveOrder}
                />
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-md-12'>
                <InvoiceableTable
                  project_task={projectTask}
                  task_order={taskOrder}
                  active_order={activeOrder}
                  user={user}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskOrderShow;
