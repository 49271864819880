import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from "react-router-dom";

import InventoryShow from '../components/projects_management/inventory/InventoryShow';
import InventoryInOut from '../components/projects_management/inventory/InventoryInOut';
import InventoryMaterials from '../components/projects_management/inventory/InventoryMaterials';
import InventorySuppliers from '../components/projects_management/inventory/InventorySuppliers';

export default class PmInventoryContainer extends Component {
  render() {
    let { auth } = this.props;
    let { user } = auth;

    return (
      <Router>
        <div className='app pb-5 project-show'>
          <div className='container bg-white my-4 p-4'>
            <div className='row'>
              <div className='col-md-12'>
                <ul className="nav nav-tabs pm-tabs">
                  { (!user.sub_organization_user || (user.sub_organization_user && user.has_global_inventory_access)) &&
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/projects_management/inventory">
                      Current Inventory
                    </NavLink>
                  </li>
                  }
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/projects_management/inventory/historical_in_out">
                      Historical IN/OUT
                    </NavLink>
                  </li>
                  { !user.sub_organization_user &&
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/projects_management/inventory/materials">
                      Materials
                    </NavLink>
                  </li>
                  }
                  { !user.sub_organization_user &&
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/projects_management/inventory/suppliers">
                      Suppliers
                    </NavLink>
                  </li>
                  }
                </ul>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <Routes>
                  { (!user.sub_organization_user || (user.sub_organization_user && user.has_global_inventory_access)) &&
                    <Route
                      path="/projects_management/inventory"
                      element={<InventoryShow user={user} />}
                    />
                  }

                  <Route
                    path="/projects_management/inventory/historical_in_out"
                    element={<InventoryInOut />}
                  />
                  { !user.sub_organization_user &&
                    <Route
                      path="/projects_management/inventory/materials"
                      element={<InventoryMaterials />}
                    />
                  }
                  { !user.sub_organization_user &&
                    <Route
                      path="/projects_management/inventory/suppliers"
                      element={<InventorySuppliers />}
                    />
                  }
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}
