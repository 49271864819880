import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import { ShowHeaderLoader} from '../../helpers/Loaders'

import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';

import api from '../../../../utils/pm_project_tasks_api'
import ConfirmModal from "./ConfirmModal"

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      jump_value: 1,
      selected_report: '',
      show_success_message: false,
      open_confirm_modal: false,
    }

    this.jumpToZone = this.jumpToZone.bind(this)
    this.generateReport = this.generateReport.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.processAcceptModal = this.processAcceptModal.bind(this)
  }

  processAcceptModal(e) {
    this.setState({open_confirm_modal: false}, () => {
      this.props.changeActiveView('edit')
    })
  }

  jumpToZone(e) {
    let value = e.target.dataset.section
    window.location = window.location.origin + window.location.pathname + window.location.search + `#${value}`
  }

  handleEdit(e) {
    let { new_form_input } = this.props.project_task.project_task_type
    if(new_form_input == 'any_with_web_warning') {
      this.setState({open_confirm_modal: true})
    } else {
      this.props.changeActiveView('edit')
    }
  }

  generateReport(e, rt) {
    let value = e.target.dataset.report
    if(value == 'pdf') {
      api.createAttachment(this.props.project_task.id , {file_type: 'pdf', project_task_file_template_id: rt.id}).then((response) => {
        this.setState({show_success_message: true}, () =>{
          setTimeout(() => {
            this.setState({show_success_message: false})
          }, 5000)
        })
      })
    } else {
      api.createAttachment(this.props.project_task.id , {file_type: rt.file_type, project_task_file_template_id: rt.id}).then((response) => {
        this.setState({show_success_message: true}, () =>{
          setTimeout(() => {
            this.setState({show_success_message: false})
          }, 5000)
        })
      })
    }
  }

  render() {
    let { project_task, form_design, report_templates } = this.props
    let { access } = project_task
    let { new_form_input } = project_task.project_task_type

    let section_values = []
    form_design.forEach((part) => {
      if(part.type == 'section') {
        section_values.push({value: part.key, label: part.label})
      }
    })

    let edit_label = (project_task.submission_id || project_task.has_submission) ? 'Edit Form' : 'Start Form'

    return(
      <Fragment>
        { this.props.loading ? (
          <ShowHeaderLoader />
        ) : (
          <div className='bg-white px-4'>
            { this.state.show_success_message &&
              <div className='row'>
                <div className='col-md-12'>
                  <div className="alert alert-success" role="alert">
                    Your report was generated. You can check it at <strong>Attachements</strong> tab
                  </div>
                </div>
              </div>
            }
            <div className='row'>
              { project_task.submission_id && !project_task.finished_uploading_images &&
                <div className='col-md-12 mb-2' style={{color: "#ff9560", fontWeight: 400}}>
                  <div className='pull-right'>
                    <i className='fa fa-cloud-upload'></i> <span>Uploading images from device</span>
                  </div>
                </div>
              }

              <div className='col-sm-12 col-md-4'>

                { section_values.length > 0 &&

                  <div className='form-jump'>

                    <div className="dropdown">
                      <button className="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Jump to a Section
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {section_values.map((sec) => {
                          return(
                            <a key={sec.value} onClick={this.jumpToZone} data-section={sec.value} className="dropdown-item">{sec.label}</a>
                          )
                        })}
                      </div>
                    </div>

                  </div>
                }

                { section_values.length > 0 &&

                  <div className='form-jump m-4 fixed-bottom'>

                    <div className="dropdown">
                      <button className="btn btn-sm btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Section List
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a key='home' onClick={this.jumpToZone} data-section='top-page-header' className="dropdown-item">-- Top of Page --</a>
                        {section_values.map((sec) => {
                          return(
                            <a key={sec.value} onClick={this.jumpToZone} data-section={sec.value} className="dropdown-item">{sec.label}</a>
                          )
                        })}
                      </div>
                    </div>
                </div>
              }


              </div>

              <div className='col-sm-12 col-md-8'>
                <div className='project-status pull-right'>


                    <div className='d-inline-block me-2'>
                      { this.props.active_view == 'show' &&
                        <div className="dropdown">
                            <button className="btn btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              Generate report
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              { access.can_report_form && report_templates.map((rt, index) => {
                                return(
                                  <a key={index} onClick={(e) => this.generateReport(e, rt)} data-report={rt.file_type} className="dropdown-item">
                                    {rt.name}
                                  </a>
                                )
                              })}
                              <a href={"/projects_management/project_task_images/" + this.props.project_task.id} target="_blank" data-report="pictures" className="dropdown-item">Pictures</a>

                            </div>

                        </div>
                      }
                    </div>

                  { access.can_edit_form &&
                    <Fragment>
                      { this.props.active_view == 'show' ? (
                        <button
                          onClick={() => this.handleEdit()}
                          type="button"
                          className="btn btn-outline-primary">{edit_label}</button>
                      ) : (
                        <Fragment>
                          <button
                            onClick={() => this.props.changeActiveView('show')}
                            type="button"
                            className="btn btn-outline-secondary">Cancel</button>

                          <button
                            onClick={this.props.handleEdit}
                            type="button"
                            className="btn btn-primary ms-2">Save</button>
                        </Fragment>
                      ) }
                    </Fragment>
                  }
                </div>
              </div>
            </div>
          </div>
        )}

        { this.state.open_confirm_modal &&
          <ConfirmModal
            handleClose={() => this.setState({open_confirm_modal: false})}
            handleProcess={this.processAcceptModal}
          />
        }


      </Fragment>
    )
  }
}

