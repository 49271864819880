import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import api from '../utils/schema_editor_api'
import { JSONEditor } from '@json-editor/json-editor'

export default class SchemaEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      schema: {},
      formData: {},
      loading_submit: false,
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)

    this.loadEditor = this.loadEditor.bind(this)
  }

  onCancel() {
    window.location = document.referrer
  }

  loadEditor() {
    let editor = new JSONEditor(document.getElementById('schema-editor'),{
      iconlib: "fontawesome4",
      theme: 'bootstrap4',
      schema: this.state.schema,
      startval: this.state.formData,
      disable_edit_json: true,
      no_additional_properties: true,
      disable_array_delete_last_row: true,
      required_by_default: true,
      array_controls_top: false,
      disable_properties: true
    })
    this.setState({editor: editor})
  }

  onSubmit(e) {
    let { resource_type, resource_id, resource_attribute } = this.props
    let formData = JSON.stringify(this.state.editor.getValue())
    if(this.state.loading_submit) {
      return
    }
    this.setState({loading_submit: true}, () => {
      api.setAttribute({resource_type, resource_id, resource_attribute, formData})
      .then((response) => {
        window.location = document.referrer
        this.setState({
          loading_submit: false,
          formData: formData
        })
      }).catch((error) => {
        alert(`Error updating configuration: ${error.response.data.message}`)

        this.setState({
          loading_submit: false
        })
      })
    })
  }

  componentDidMount() {
    let { resource_type, resource_id, resource_attribute } = this.props

    api.getSchema({resource_type, resource_id, resource_attribute}).then((response) => {
      this.setState({
        schema: response.schema,
        formData: response.form_data,
        schema_title: response.schema_title
      }, () => {
        this.loadEditor()
      })
    })
  }

  render() {
    let { schema, formData, loading_submit, schema_title } = this.state
    let submit_text = loading_submit ? 'Submitting ...' : 'Update'
    return(
      <div className='app py-3 px-4 project-show'>
        <div className='container-fluid'>
          <div className='row mt-3 bg-white'>
            <div className='col-md-12 mt-4 px-4'>
              <div className='d-inline-block'>
                <h4>{schema_title}</h4>
                <p>{this.props.resource_attribute}</p>
              </div>
              <div className='d-inline-block pull-right'>
                <button className='btn btn-light me-2' onClick={this.onCancel}>
                  Cancel
                </button>

                <button className='btn btn-primary' onClick={this.onSubmit}>
                  {submit_text}
                </button>
              </div>
            </div>

            <div className='col-md-12'>
              <div id='schema-editor' className="mx-3 my-3">
              </div>
            </div>
         </div>
        </div>
      </div>

    )
  }
}
