import React, { Component, Fragment, useState } from 'react'
import { render } from 'react-dom'
import Modal from 'react-bootstrap/Modal'

const ConfirmModal = ({handleClose, handleProcess }) => {
  const [comment, setComment] = useState("")

  let label = 'Continue'
  let btn = "btn btn-primary"

  return(
    <Modal show={true} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title className=''>
          <i className='fa fa-exclamation-triangle me-1'></i> Warning
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          If this form is started here system will not process mobile app submissions.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="btn btn-secondary">Close</button>
        <button className={btn} onClick={() => handleProcess(comment)}>{label}</button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
