import React, { Component, Fragment, useState } from 'react'
import CampodataForm from './CampodataForm'
import api from '../../utils/pm_project_tasks_api'
import NewSubForm from './NewSubForm'

import PendingForm from "./PendingForm";

const entryName = (index, design, items) => {
  let name = `Entry ${index}`
  let { single_item_label, multiple_item_label, item_label_template} = design
  if(single_item_label) {
    name = single_item_label.replace('%', index)
  }

  if(item_label_template) {
    items.forEach((item) => {
      let index = item_label_template.indexOf('{{'+item.id+"}}")
      if(index != -1) {
        item_label_template = item_label_template.replace('{{'+item.id+"}}", item.value || '')
      }else {
        item_label_template = item_label_template.replace('{{'+item.id+"}}", '')
      }
    })
    name = `${item_label_template}`
    name = name.replace(/{{[\w-]+}}/gm,'')
    name = name.trim()
  }

  return name
}

const CardList = (props) => {
  let { view, index, parent_key, design, items, resource_type, resource_id, item_group, item_uuid, pending_submission } = props
  let name = `id-${parent_key}-${index}`
  let heading_name = `heading-${index}`

  if(pending_submission) {
    name = `id-${parent_key}-${index}-${pending_submission.id}`
    heading_name = `heading-${index}-${pending_submission.id}`
  }

  let sub_fields = design.subfields
  let new_items = []
  items.forEach((item) => {
    let sf = sub_fields.find(n => n.key == item.id)
    if(sf) {
      item.type = sf.type
      new_items.push(item)
    }
  })

  let submission_data = {
    data: new_items
  }

  let is_open = props.open_tab == name ? true : false

  return(
    <div className="card">
      <div className="card-header py-1" id={heading_name}>
        <h2 className="mb-0">
          <button onClick={() => props.setOpen(is_open ? "" : name) }className="btn btn-link" type="button" data-toggle="collapse" data-target={"#" + name} aria-expanded="true" aria-controls={name}>
            { entryName(index, design, items)}
          </button>
        </h2>
      </div>

      <div id={name} className="collapse" aria-labelledby={heading_name} data-parent={"#" + `${parent_key}${pending_submission ? pending_submission.id : ''}`}>
        <div className="card-body">
          { is_open &&
            <>
              { pending_submission ? (
                <PendingForm
                  resource_type={resource_type}
                  resource_id={resource_id}
                  pending_submission={pending_submission}
                  form_design={design.subfields}
                  item_group={item_group}
                  item_uuid={item_uuid}
                  parent_key={parent_key}
                  submission_data={submission_data}
                />
              ) : (
                <CampodataForm
                  view={view}
                  form_design={design.subfields}
                  submission_data={submission_data}
                  resource_type={resource_type}
                  resource_id={resource_id}
                  name={heading_name + "-" + item_group}
                  item_group={item_group}
                  parent_key={parent_key}
                  sub_form_type={'persisted'}
                  updateSubmissionData={(data) => props.updateSubFormSubmissionData(data, item_group)}
                />
              )}
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default class SubForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      sub_forms: [],
      open_new_form: false,
      save_data: null,
      new_view_type: 'edit',
      saving: false,
      open_tab: "",
    }

    this.getSubForms = this.getSubForms.bind(this)
    this.handleNewSubFormItem = this.handleNewSubFormItem.bind(this)
    this.cancelNew = this.cancelNew.bind(this)
    this.saveNewEntry = this.saveNewEntry.bind(this)
    this.updateSubFormSubmissionData = this.updateSubFormSubmissionData.bind(this)
  }

  updateSubFormSubmissionData(data, item_group) {
    let key = this.props.design.key
    this.props.updateSubForm(key, {
      item_group,
      data,
      key
    })
  }

  getSubForms() {
    let {resource_id, design, pending_submission, value} = this.props

    if(pending_submission) {
      let key = design.key
      let item_uuids = value.items
      let data = JSON.parse(pending_submission.data)

      let items = item_uuids.map((item_uuid, index) => {
        let items_map = ((data.item_list_items || {})[key] || {})[item_uuid] || {}

        return {
          item_group: index,
          item_uuid: item_uuid,
          items: Object.values(items_map)
        }
      })
      this.setState({
        loading: false,
        sub_forms: items,
        render: true
      })
    } else {
      return api.getSubFormItems(resource_id, design.key).then((response) => {
        this.setState({loading: false, sub_forms: response.project_tasks, render: true})
      })
    }
  }

  cancelNew(item_group) {
    let {resource_id, design} = this.props

    api.cancelNewItem(resource_id, design.key, item_group).then((response) => {
      this.getSubForms().then(() => {
        this.setState({open_new_form: false, save_data: null, render: true})
      })
    })
  }

  saveNewEntry(item_group) {
    if(this.state.saving) {
      return
    }

    let { save_data } = this.state
    if(save_data) {
      this.setState({new_view_type: 'show', saving: true, render: true}, () => {
        api.createSubFormItem(this.props.resource_id, this.props.design.key, save_data, item_group).then((response) => {
          this.getSubForms().then(() => {
            this.setState({new_view_type: 'edit', saving: false, open_new_form: false, save_data: null, render: true})
          })
        })
      })
    }
  }

  componentWillMount() {
    this.getSubForms()
  }

  handleNewSubFormItem(data) {
    this.setState({save_data: data, render: false})
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.render) {
      return true;
    }
    return false;
  }

  render() {
    let { design, resource_type, resource_id, view, pending_submission } = this.props
    let sub_forms = this.state.sub_forms
    let parent_key = design.key
    let max_group = 0

    sub_forms.forEach((sub_form) => {
      if(sub_form.item_group > max_group) {
        max_group = sub_form.item_group
      }
    })

    if(sub_forms.length > 0) {
      max_group = max_group + 1
    }

    let new_heading_name = `heading-${max_group}`

    return(
      <Fragment>
        { this.state.loading ? (
          <div>Loading</div>
        ) : (
          <Fragment>
            { sub_forms.length == 0 ? (
              <p className='text-secondary mb-0'>0 entries</p>
            ) : (
              <div className="accordion" id={`${parent_key}${pending_submission ? pending_submission.id : ''}`}>
                { sub_forms.map((sub_form, index) => {
                  return(
                    <CardList
                      key={sub_form.item_group}
                      index={index}
                      item_group={sub_form.item_group}
                      item_uuid={sub_form.item_uuid}
                      items={sub_form.items}
                      parent_key={parent_key}
                      design={design}
                      resource_type={resource_type}
                      resource_id={resource_id}
                      view={view}
                      open_tab={this.state.open_tab}
                      setOpen={(open_tab) => this.setState({open_tab})}
                      updateSubFormSubmissionData={this.updateSubFormSubmissionData}
                      pending_submission={pending_submission}
                    />
                  )
                })}
              </div>
            )}

            { this.state.open_new_form ? (
              <div className="card mt-1">
                <div className="card-header p-0 ps-2">
                  <div className='text-primary py-2 ps-4'>
                    New Entry
                  </div>
                </div>

                <div className="card-body">
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className="project-status pull-right">
                        <button type="button" onClick={() => this.cancelNew(max_group)} className="btn btn-outline-secondary">
                          Cancel
                        </button>
                        <button type="button" onClick={() => this.saveNewEntry(max_group)} className="btn btn-primary ms-2">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <NewSubForm
                        view={this.state.new_view_type}
                        form_design={design.subfields}
                        resource_type={resource_type}
                        resource_id={resource_id}
                        name={new_heading_name + "-" + max_group}
                        item_group={max_group}
                        parent_key={parent_key}
                        handleNewSubmissionData={this.handleNewSubFormItem}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='mt-2'>
                { this.props.view == 'edit' &&
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.setState({open_new_form: true, render: true})}>
                    <i className='fa fa-plus'></i> Add New Entry
                  </button>
                }
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }
}
