import React, { useState } from 'react'
import EditClassifier from './EditClassifier';
import FileView from './FileView';
import ImageSlider from './ImageSlider';

import { useDeleteAttachment } from '../document_groups/hooks/useAttachments';
import { useUserContext } from '../../../hooks/context/useUserContext'

const TableView = ({attachments, resource_type, resource_id}) => {
  const [selectedFileIndex, setSelectedFileIndex] = useState(0)
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const { mutate: deleteAttachment } = useDeleteAttachment({resource_id, resource_type})

  const { auth } = useUserContext();

  const onImageClick = (index) => {
    setSelectedFileIndex(index)
    setLightboxOpen(true)
  }

  const handleRemove = (attachment_id) => {
    deleteAttachment(attachment_id)
  }

  return (
    <div>
      {attachments && attachments.length > 0 && (
        <table className="table table-image table-sm table-stripped">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Nombre</th>
              <th scope="col" style={{ width: "25%" }}>
                Classifier A
              </th>
              {auth.attachments.delete && <th></th>}
            </tr>
          </thead>
          <tbody>
            {attachments.map((attachment, index) => {
              return (
                <tr key={attachment.uuid}>
                  <td className="w-25">
                    <FileView
                      file={attachment}
                      onImageClick={() => onImageClick(index)}
                    />
                  </td>
                  <td>
                    <div>{attachment.name}</div>
                    <small className="text-secondary">
                      {attachment.created_at_label}
                    </small>
                  </td>
                  <td>
                    <EditClassifier
                      resource_id={resource_id}
                      resource_type={resource_type}
                      type="a"
                      attachment={attachment}
                    />
                  </td>

                  {auth.attachments.delete && (
                    <td>
                      <small
                        className="text-secondary cursor-pointer"
                        onClick={() => handleRemove(attachment.id)}
                      >
                        Remove
                      </small>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {lightboxOpen && (
        <ImageSlider
          onClose={() => setLightboxOpen(false)}
          selectedFileIndex={selectedFileIndex}
          files={attachments}
        />
      )}
    </div>
  );
};

export default TableView;
