import React, { Fragment, useState } from "react";
import { ProcessType } from "./config";
import ArrowSvg from "./arrow.svg";
import { remove } from "lodash";

let delete_style = { backgroundColor: "#fff0f1" };
let new_style = { backgroundColor: "#f1fff4" };

export const SelectableRow = ({
  task,
  job,
  importer,
  setTasks,
  tasks,
  new_identifier_map,
  used_new_numbers,
  used_numbers_map,
  setUsedNewNumbers,
  only_view,
  new_tasks
}) => {
  let style = task.process_type == "to_delete" ? delete_style : new_style;
  let replace_task = used_numbers_map[task.transfer_to_number];
  let table_settings = job.table_settings || {};
  let options = new_identifier_map[task.identifier] || [];

  if (task.out_of_sync) {
    options = new_tasks
  }

  options = options.filter(e => !used_new_numbers.includes(Number(e.number)) || Number(e.number) == Number(task.transfer_to_number))

  const [processType, setProcessType] = useState(task.transfer_to_number ? "replace" : "remove");

  const onOptionChange = (e) => {
    if (only_view) {
      return
    }

    let value = e.target.value;
    let remove_transfer_to_number = null;
    if (value == "remove") {
      let new_tasks = tasks.map((t) => {
        if (t.number == task.number) {
          remove_transfer_to_number = t.transfer_to_number;
          t.transfer_to_number = null;
        }

        return t;
      });

      let new_used_new_numbers = used_new_numbers.filter(
        (t) => t != remove_transfer_to_number
      );

      setUsedNewNumbers(new_used_new_numbers);
      setTasks(new_tasks);
    } else {
      let new_task = options[0];

      if (new_task) {
        let new_tasks = tasks.map((t) => {
          if (Number(t.number) == Number(task.number)) {
            t.transfer_to_number = Number(new_task.number);
          }

          return t;
        });

        let new_used_new_numbers = used_new_numbers.concat([Number(new_task.number)]);

        setUsedNewNumbers(new_used_new_numbers);
        setTasks(new_tasks);
      }
    }

    setProcessType(e.target.value);
  };

  const changeTransferNumber = (number) => {
    if (only_view) {
      return
    }

    let remove_number = null;
    let new_tasks = tasks.map((t) => {
      if (t.number == task.number) {
        remove_number = t.transfer_to_number;
        t.transfer_to_number = Number(number);
      }

      return t;
    });

    let new_used_new_numbers = used_new_numbers.filter(
      (t) => t != remove_number
    );

    new_used_new_numbers = new_used_new_numbers.concat([Number(number)]);

    setUsedNewNumbers(new_used_new_numbers);
    setTasks(new_tasks);
  };

  if (task.process_type == "new" && used_new_numbers.includes(Number(task.number))) {
    return <Fragment key={task.number}></Fragment>;
  }

  let disabled = only_view

  return (
    <Fragment key={task.number}>
      <tr style={style}>
        <td colSpan="2">
          <p className="mb-0">
            #{task.number} -
            <span className="text-uppercase text-secondary fw-bold">
              {" "}
              {task.classifier_a}
            </span>{" "}
            - {task.name}  {!task.out_of_sync ? (<ProcessType task={task} v2={true} />) : ""} {task.db_record_exists ? (<span className="small text-uppercase text-danger">Existing Task</span>) : ""} {task.out_of_sync && <span className="text-danger small text-uppercase">not present in file</span>}

            {task.db_record_exists ?
              (
                <span className="d-block ms-5 small text-uppercase text-secondary">{task.db_record_summary}</span>
              ) : (<span className="small text-uppercase text-secondary"></span>)
            }
          </p>


          {task.process_type == "to_delete" && (task.db_record_exists || task.out_of_sync) && !task.previous_revised && options.length > 0 && (
            <div className="small form-group my-2 ps-5">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  Existing Task Assignment, Status and Attachments:
                </label>
                <input
                  className="form-check-input ms-2"
                  type="radio"
                  name={`types-${task.number}`}
                  value="remove"
                  id={`remove-${task.number}`}
                  checked={processType === "remove"}
                  onChange={onOptionChange}
                  disabled={disabled}
                />
                <label className="form-check-label" htmlFor={`remove-${task.number}`}>
                  Delete
                </label>

                <input
                  className="form-check-input ms-2"
                  type="radio"
                  name={`types-${task.number}`}
                  value="replace"
                  id={`replace-${task.number}`}
                  checked={processType === "replace"}
                  onChange={onOptionChange}
                  disabled={disabled}
                />
                <label className="form-check-label" htmlFor={`replace-${task.number}`}>
                  Move to New Task
                </label>
              </div>

              {processType == "replace" && (
                <div className="form-check form-check-inline">
                  <select
                    className="form-control form-control-sm"
                    disabled={disabled}
                    onChange={(event) =>
                      changeTransferNumber(event.target.value)
                    }
                    value={task.transfer_to_number}
                  >
                    {options.map((option) => {
                      return (
                        <option value={option.number} key={option.number}>
                          {task.out_of_sync ? (
                            `#${option.number} | ${option.name} ${option.quantity} @ ${option.unit_cost} = ${option.item_amount}`
                          ) : (
                            `#${option.number} | ${option.quantity} @ $${option.unit_cost} = $${option.item_amount}`
                          )
                          }
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
          )}
        </td>

        {table_settings.extra_column && <td>{task.extra_column}</td>}

        <td>{processType == 'replace' ? (<span className="text-secondary" style={{ textDecoration: "line-through" }}>{task.past_quantity}</span>) : (`${task.quantity}`)}</td>
        <td>${processType == 'replace' ? (<span className="text-secondary" style={{ textDecoration: "line-through" }}>{task.past_unit_cost}</span>) : (`${task.unit_cost}`)}</td>

        {job.show_taxes && (
          <Fragment>
            <td>${processType == 'replace' ? (<span className="text-secondary" style={{ textDecoration: "line-through" }}>{task.past_original_cost}</span>) : (task.original_cost)}</td>
            <td>${processType == 'replace' ? (<span className="text-secondary" style={{ textDecoration: "line-through" }}>{task.past_tax_cost}</span>) : (task.tax_cost)}</td>
          </Fragment>
        )}

        <td>${processType == 'replace' ? (<span className="text-secondary" style={{ textDecoration: "line-through" }}>{task.past_item_amount}</span>) : (`${task.item_amount}`)}</td>
      </tr>

      {replace_task && (
        <Fragment>
          <tr style={new_style}>
            <td style={{ backgroundColor: 'white', border: '0' }}>
              <img src={ArrowSvg} width="20" className="ms-2 mb-2" />
            </td>
            <td>
              <p className="ms-2 mb-0 d-inline-block">
                #{replace_task.number} -
                <span className="text-uppercase text-secondary fw-bold">
                  {" "}
                  {replace_task.classifier_a}
                </span>{" "}
                - {replace_task.name} <ProcessType task={replace_task} v2={true} />
              </p>
            </td>

            {table_settings.extra_column && <td>{replace_task.extra_column}</td>}

            <td>{replace_task.quantity}</td>
            <td>${replace_task.unit_cost}</td>

            {job.show_taxes && (
              <Fragment>
                <td>${replace_task.original_cost}</td>
                <td>${replace_task.tax_cost}</td>
              </Fragment>
            )}

            <td>${replace_task.item_amount}</td>

          </tr>
          <tr><td style={{ border: "0", height: "3px" }} colSpan={5}></td></tr>
        </Fragment>
      )}
    </Fragment>
  );
};
