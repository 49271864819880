import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { useGetEntries, useUpdateEntry } from "../../hooks/useTimesheet";
import EntryForm from "./EntryForm";

const UpdateDateEntriesModal = ({ user, date, onClose }) => {
  const { data: entries, isPending } = useGetEntries(user.user_id, date);
  const [currentEntry, setCurrentEntry] = useState(null);
  const [step, setStep] = useState(1); // 1 for entry form, 2 for confirmation
  const [notes, setNotes] = useState("");
  const [changeDetails, setChangeDetails] = useState({});
  const handleEntryEdit = (entryId, newMinutes, originalTime, newTime) => {
    const entry = entries.find((e) => e.id === entryId);
    setCurrentEntry({ ...entry, newMinutes });
    setChangeDetails({ originalTime, newTime });
    setStep(2);
  };
  const { mutate: updateEntry, isPending: isSubmitting } = useUpdateEntry(
    user.user_id,
    date
  );

  const confirmUpdate = () => {
    updateEntry(
      {
        entryId: currentEntry.id,
        data: {
          minutes_worked: currentEntry.newMinutes,
          notes,
        },
      },
      {
        onSuccess: () => {
          setCurrentEntry(null);
          setNotes("");
          setStep(1);
          onClose();
        },
      }
    );
  };

  const handleClose = () => {
    if (isSubmitting) return;
    onClose();
  };

  return (
    <Modal show={true} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4 className="text-secondary mb-0">
            {step === 1 ? "Update Time Entry" : `Confirm Update`}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step === 1 && (
          <>
            <div className="row mb-4">
              <div className="col-12 d-flex align-items-center">
                <h5 className="me-2 mb-0">{user.name}</h5>
                <span className="text-secondary">
                  {moment(date).format("ddd D MMM, YYYY")}
                </span>
              </div>
            </div>
            {isPending && <div className="text-center my-4">Loading...</div>}
            {!isPending &&
              entries.map((entry) => (
                <EntryForm
                  key={entry.id}
                  entry={entry}
                  onSave={handleEntryEdit}
                />
              ))}
          </>
        )}
        {step === 2 && currentEntry && (
          <>
            {currentEntry.certified_by_user_at && (
              <div className="alert alert-warning">
                This entry has been certified by the user
              </div>
            )}

            <span>
              Change entry from <strong>{changeDetails.originalTime}</strong> to{" "}
              <strong>{changeDetails.newTime}</strong>
            </span>

            <textarea
              className="form-control my-3"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Add a note"
            ></textarea>
            <div className="mt-3 d-flex justify-content-end">
              <Button
                variant="secondary"
                className="me-2"
                onClick={() => setStep(1)}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={confirmUpdate}
                disabled={isSubmitting}
              >
                Confirm
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UpdateDateEntriesModal;
