import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import ReactTable from "react-table"

import inventoryApi from '../../../../utils/pm_inventory_api'

import BaseTable from '../../tables/BaseTable'
import { Link } from "react-router-dom"

import Select from 'react-select'
import { DateRangePicker } from 'react-dates'

import ExportTableButton from '../../helpers/ExportTableButton'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

export default class ResumeTable extends BaseTable {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      loading: true,
      loading_datasources: true,
      table: {
        headers: [],
        rows: [],
      },
      filters: {
      }
    }

    this.columns = this.columns.bind(this)
    this.handleValue = this.handleValue.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
    this.selectDateRange = this.selectDateRange.bind(this)

    this.tableHeaders = this.tableHeaders.bind(this)
    this.exportRows = this.exportRows.bind(this)
  }

  selectDateRange(data) {
    let filters = this.state.filters
    let start_date = data.startDate
    let end_date = data.endDate

    filters.start_date = start_date
    filters.end_date = end_date
    this.setState({filters: filters})
  }

  handleValue(type, e) {
    let filters = this.state.filters
    filters[type] = e
    this.setState({filters})
  }

  handleFilter() {
    this.setState({loading: true}, () => {
      this.fetchData()
    })
  }

  componentWillMount() {
    inventoryApi.getDatasources().then((response) => {
      this.setState({loading_datasources: false, datasources: response}, () => {
        this.fetchData()
      })
    })
  }

  fetchData() {
    let filters = {...this.state.filters}

    if(filters.start_date) {
      filters.start_date = filters.start_date.toISOString()
    }

    if(filters.end_date) {
      filters.end_date = filters.end_date.toISOString()
    }

    inventoryApi.getResumeTable(filters).then((data) => {
      this.setState({table: data, loading: false})
    })
  }

  columns() {
    let { table } = this.state
    let _columns = [
      {
        Header: 'Materials',
        accessor: 'inventory_item',
        filterable: true,
        sortable: true,
        width: 250,
        style: { 'whiteSpace': 'unset' },
        className: ''
      }
    ]

    table.headers.forEach((header, index) => {
      _columns.push({
        Header: header,
        accessor: `col_${index}`,
        filterable: false,
        sortable: true,
        Cell: ({original}) => {
          let value = original[`col_${index}`]
          let cname = "text-center"
          if(value && value < 0) {
            cname = cname + " text-danger font-weight-light"
          }
          return(
            <div className={cname}>
              {value}
            </div>
          )
        }
      })
    })

    _columns.push({
      Header: 'Total',
      accessor: 'total',
      filterable: false,
      sortable: true,
      Cell: ({original}) => {
        let value = original.total
        let cname = "text-center"
        if(value && value < 0) {
          cname = "text-danger text-center"
        }
        return(
          <div className={cname}>
            <strong>{value}</strong>
          </div>
        )
      }
    })

    return(_columns)
  }

  tableHeaders() {
    let headers = ['Materials']
    let { table } = this.state
    table.headers.forEach(header => headers.push(header))

    headers.push('Total')

    return headers
  }

  exportRows() {
    let rows = []
    let { table } = this.state

    table.rows.forEach((record) => {
      let row = [record.inventory_item]

      table.headers.forEach((h, index) => {
        row.push(record[`col_${index}`])
      })

      row.push(record.total)

      rows.push(row)
    })
    return rows
  }

  filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    let scape_regex = filter.value.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1")
    return (
      row[id] !== undefined ?
      String(row[id].toLowerCase()).match(scape_regex) : true
    )
  }

  render() {
    let { table, loading, filters, datasources, loading_datasources } = this.state

    let headers = this.tableHeaders()
    let export_rows = this.exportRows()

    return(
      <Fragment>
        { !loading_datasources && !this.props.user.sub_organization_user &&
          <div className='row mt-2'>
            <div className='col-md-12'>
              <div className= 'pull-left'>
                <div className='d-inline-block me-2 datepicker-small-select' style={{width: '318px'}}>
                  <DateRangePicker
                    orientation="horizontal"
                    verticalHeight={568}
                    hideKeyboardShortcutsPanel
                    showClearDates={true}
                    startDate={filters.start_date}
                    startDateId="start_date_id"
                    startDatePlaceholderText={i18n.t('explore.filters.date_range_start')}
                    endDate={filters.end_date}
                    endDateId="end_date_id"
                    endDatePlaceholderText={i18n.t('explore.filters.date_range_end')}
                    onDatesChange={this.selectDateRange}
                    focusedInput={this.state.focusedInput}
                    isOutsideRange={day => (false)}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                  />
                </div>



                <div className='d-inline-block me-2' style={{width: '230px'}}>
                  <Select
                    styles={selectStyles}
                    value={filters.sub_organization}
                    onChange={(e) => this.handleValue('sub_organization', e) }
                    options={datasources.sub_organizations}
                    placeholder={'Contractors'}
                    isClearable={true}
                  />
                </div>
                <div className='d-inline-block me-2' style={{width: '230px'}}>
                  <Select
                    styles={selectStyles}
                    value={filters.vendor}
                    onChange={(e) => this.handleValue('vendor', e) }
                    options={datasources.vendors}
                    placeholder={'Vendors'}
                    isClearable={true}
                  />
                </div>
                <div className='d-inline-block me-2' style={{width: '200px'}}>
                  <Select
                    styles={selectStyles}
                    value={filters.warehouse}
                    onChange={(e) => this.handleValue('warehouse', e) }
                    options={datasources.warehouses}
                    placeholder={'Warehouses'}
                    isClearable={true}
                  />
                </div>
                <div className='d-inline-block mx-2'>
                  <button onClick={this.handleFilter} className='btn btn-primary' disabled={loading}>
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        }

        <div className='row mt-2'>
          <div className='col-md-12'>
            <ExportTableButton
              export_rows={export_rows}
              headers={headers}
              filename={'current_inventory'}
              loading={loading}
            />
          </div>
        </div>

        <div className='row mt-2'>
          <div className='col-md-12'>
            <ReactTable
              className="-striped -highlight"
              data={table.rows}
              columns={this.columns()}
              defaultPageSize={20}
              loading={loading}
              defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
            />
          </div>
        </div>
      </Fragment>
    )
  }
}
