import React, {Fragment} from 'react'

export const ModalHeader = (props) => {
  let { importer, is_initial } = props

  return (
    <div className="row pb-4 pt-2" style={{ borderBottom: "2px solid #ccc" }}>
      <div className="col-md-7">
        <div className="project-meta">
          <h4>
            {importer.project_task_type.name}
            {!is_initial && <Fragment> - Change Order</Fragment>}
          </h4>
          <span className="mt-2">
            <span className="me-1">{importer.project.name}</span> /{" "}
            <span className="ms-1">{importer.name}</span>
          </span>
        </div>
      </div>

      <div className="col-md-5">
        {!props.only_view && (
          <Fragment>
            <button
              onClick={props.handleApprove}
              type="button"
              className="pull-right btn btn-primary"
            >
              Approve
            </button>

            <button
              onClick={props.handleReject}
              type="button"
              className="me-2 pull-right btn btn-outline-danger"
            >
              Reject
            </button>
          </Fragment>
        )}

        <button
          onClick={props.closeModal}
          type="button"
          className="me-2 pull-right btn btn-outline-secondary"
        >
          Close
        </button>
      </div>
    </div>
  );
}
