import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import api from '../../../utils/pm_boards_api';
import SubOrganizationFilter from './SubOrganizationFilter';
import Card from './Card';

const ProjectBoardShow = (props) => {
  const { project_board_id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [projectQueues, setProjectQueues] = useState([]);

  const getQueues = (subOrganizationFilter) => {
    api.getQueues(project_board_id, subOrganizationFilter).then((response) => {
      const queues = response.data.map((e) => e.attributes);
      setProjectQueues(queues);
      setLoading(false);
    });
  };

  const navigateToMap = (queue) => {
    navigate(`/projects_management/project_boards/${queue.project_board_id}/project_queues/${queue.id}/map_view?base=true`);
  };

  const navigateToQueue = (queue) => {
    navigate(`/projects_management/project_boards/${queue.project_board_id}/project_queues/${queue.id}`);
  };

  const setSubOrganizationFilter = (filter) => {
    setLoading(true);
    getQueues(filter);
    props.setSubOrganizationFilter(filter); // Llama la función del prop
  };

  useEffect(() => {
    getQueues(props.sub_organization_filter);
  }, [props.sub_organization_filter]);

  return (
    <div className='app pb-5 project-show'>
      <div className='container mt-4'>
        <div className="row mb-4">
          <div className='col-md-8 m-0 p-0'>
            <h3 className="ps-3">Project Tasks Board</h3>
          </div>

          <div className="col-md-4">
            <SubOrganizationFilter
              sub_organization_filter={props.sub_organization_filter}
              setSubOrganizationFilter={setSubOrganizationFilter}
            />
          </div>
        </div>

        <div className='row mt-3 pt-3 bg-white'>
          {projectQueues.map((queue) => (
            <div key={queue.id} className='col-md-4 col-sm-6 col-lg-3'>
              <Card
                queue={queue}
                navigateToQueue={navigateToQueue}
                navigateToMap={navigateToMap}
              />
            </div>
          ))}
        </div>

        {!loading && projectQueues.length === 0 && (
          <div className="alert alert-info" role="alert">
            No Project Task Queues Available
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectBoardShow;
