import React, { Fragment, useState, useEffect } from 'react'
import { render } from 'react-dom'

import Select from 'react-select'
const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: 9999 })
}

const MapFilters = ({map_filters, filters, setFilters}) => {

  const handleFilter = (type, e) => {
    let new_filters = {...filters}
    new_filters[type] = e
    setFilters(new_filters)
  }

  return(
    <Fragment>
      { map_filters.map((filter) => {
        if(filter.key == 'scheduled_at' || !filter.key.startsWith('pdd_')) {
          return(<></>)
        }

        return(
          <div key={filter.key} className='d-inline-block ms-4'>

            <div className='small d-inline-block' style={{width: '20vw'}}>
              <Select
                isMulti={true}
                closeMenuOnSelect={false}
                placeholder={filter.label}
                styles={selectStyles}
                value={filters[filter.key]}
                onChange={(e) => handleFilter(filter.key, e) }
                options={filter.options}
                isClearable={true}
              />
            </div>
          </div>
        )
      })}
    </Fragment>
  )
}

export default MapFilters
