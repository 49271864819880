import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Dropzone from 'react-dropzone'
import TrStatus from './TrStatus'
import CheckModal from './CheckModal'
import CheckModalV2 from './CheckModalComponent/CheckModal'
import uuid from 'node-uuid'

import axios from 'axios'
const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token

//import AttachmentFiles from './AttachmentFiles'

class ImportersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_prev_modal: false,
      show_is_initial: false,
      selected_imported: undefined,
    };
  }

  render() {
    let { importers } = this.props;
    let total_importers = importers.length;
    if (total_importers == 0) {
      return <div></div>;
    }

    let all_initial = !importers.find((e) => e.is_initial);

    return (
      <Fragment>
        <table className="table my-2" style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th scope="col" style={{ width: "35%" }}>
                Uploaded File
              </th>
              <th scope="col">Uploaded at</th>
              <th scope="col">User</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {importers.map((importer, index) => {
              let is_initial = importer.is_initial || all_initial;
              return (
                <tr key={importer.id}>
                  {["completed", "processing", "rejected"].includes(
                    importer.status
                  ) ? (
                    <td>
                      <a
                        className="cursor-pointer text-primary"
                        onClick={() =>
                          this.setState({
                            selected_importer: importer,
                            show_prev_modal: true,
                            show_is_initial: is_initial,
                          })
                        }
                      >
                        {importer.name}
                      </a>
                      <a href={importer.file_url} className="ms-2">
                        <i className="fa fa-download"></i>
                      </a>
                      {!is_initial && (
                        <div className="text-secondary">Change Order</div>
                      )}
                    </td>
                  ) : (
                    <td>
                      <a href={importer.file_url}>{importer.name}</a>
                      <a href={importer.file_url} className="ms-2">
                        <i className="fa fa-download"></i>
                      </a>
                      {!is_initial && (
                        <div className="text-secondary">Change Order</div>
                      )}
                    </td>
                  )}
                  <td>{importer.created_at_label}</td>
                  <td>{importer.user.name}</td>
                  <td>
                    <TrStatus
                      reloadTable={this.props.reloadTable}
                      importer={importer}
                      is_initial={is_initial}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {this.state.show_prev_modal && (
          <>
            {this.state.selected_importer.importer_type ==
            "xactimate_importer" ? (
              <CheckModal
                closeModal={() =>
                  this.setState({
                    show_prev_modal: false,
                    selected_importer: undefined,
                  })
                }
                handleApprove={this.handleApprove}
                approve
                is_open={this.state.show_prev_modal}
                is_initial={this.state.show_is_initial}
                importer={this.state.selected_importer}
                only_view={true}
              />
            ) : (
              <CheckModalV2
                closeModal={() =>
                  this.setState({
                    show_prev_modal: false,
                    selected_importer: undefined,
                  })
                }
                handleApprove={this.handleApprove}
                approve
                is_open={this.state.show_prev_modal}
                is_initial={this.state.show_is_initial}
                importer={this.state.selected_importer}
                only_view={true}
              />
            )}
          </>
        )}
      </Fragment>
    );
  }
}

export default class ImportData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_loading: false,
      importers: [],
      uploaded_percentage: 0,
      imports_loading: true,
      initial_load: true,
      show_change_order: true,
    };

    this.onDrop = this.onDrop.bind(this);
    this.loadImporters = this.loadImporters.bind(this);
  }

  loadImporters() {
    this.setState({ imports_loading: true }, () => {
      axios
        .get(
          `/web_api/projects_management/project_tasks/${this.props.project_task.id}/project_task_importers`
        )
        .then((response) => {
          let imports = (response.data.data || []).map(
            (file) => file.attributes
          );
          this.setState({
            importers: imports,
            imports_loading: false,
            initial_load: false,
          });
        });
    });
  }

  componentWillMount() {
    this.loadImporters();
  }

  onDrop(files) {
    let new_loading = this.state.new_loading;

    if (!new_loading) {
      this.setState({ new_loading: true }, () => {
        let files_map = files.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
            uploaded: false,
            uuid: uuid.v4(),
            extension: file.name.split(".").pop(),
          });
        });

        let extension = files_map[0].extension;

        if (extension == "csv" || extension == "xlsx") {
        } else {
          this.setState({ new_loading: false });
          return;
        }

        let files_data = files_map.map((file) => {
          return {
            name: file.name,
            uuid: file.uuid,
            extension: file.extension,
            type: file.type,
          };
        });

        let params = {
          files: files_data,
        };

        axios
          .get(
            `/web_api/projects_management/project_tasks/${this.props.project_task.id}/project_task_importers/signed_urls`,
            { params: params }
          )
          .then((response) => {
            let data = response.data.signed_urls;

            files_map = files_map.map((file) => {
              let upload_data = data[file.uuid];
              return Object.assign(file, { ...upload_data });
            });
            let file = files_map[0];

            let headers = {
              "Content-Type": file.type,
            };

            let options = {
              headers: headers,
              onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({ uploaded_percentage: percentCompleted });
              },
            };

            axios.put(file.upload_url, file, options).then((response) => {
              let data = {
                file: file,
              };

              axios
                .post(
                  `/web_api/projects_management/project_tasks/${this.props.project_task.id}/project_task_importers`,
                  data
                )
                .then((response) => {
                  this.setState(
                    { new_loading: false, show_change_order: true },
                    () => {
                      this.loadImporters();
                    }
                  );
                });
            });
          });
      });
    }
  }

  render() {
    let {
      new_loading,
      uploaded_percentage,
      importers,
      initial_load,
      show_change_order,
    } = this.state;
    let { can_create_attachment, project_task } = this.props;

    let has_initial = importers.find((e) => e.is_initial);
    let { has_task_importer } = project_task.project_task_type;

    let any_active = importers.find((e) => ['processing', 'template_generated'].includes(e.status) )

    return (
      <div>
        {new_loading && (
          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-primary" role="alert">
                Uploading file {uploaded_percentage}%
              </div>
            </div>
          </div>
        )}

        {!initial_load && (
          <Fragment>
            {has_task_importer ? (
              <div className="row">
                <div className="col-md-12">
                  {show_change_order && has_initial ? (
                    <>
                      {!any_active &&
                        <div
                          style={{ cursor: "pointer" }}
                          className="text-primary py-4 mb-2"
                          onClick={() =>
                            this.setState({ show_change_order: false })
                          }
                        >
                          <strong>
                            <center>
                              <i className="fa fa-pencil"></i> Create Change Order
                            </center>
                          </strong>
                        </div>
                      }
                    </>
                  ) : (
                    <>
                      {!any_active &&
                        <Dropzone
                          accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          className="dropzone py-4 mb-2"
                          multiple={false}
                          onDrop={this.onDrop}
                        >
                          <center className="text-secondary">
                            <u>
                              <span className="fw-bold">Choose File</span>
                            </u>{" "}
                            or Drag File Here
                          </center>
                        </Dropzone>
                      }
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="alert alert-danger" role="alert">
                    In order to upload a file you need to create a task
                    importer.
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-12">
                <ImportersTable
                  importers={importers}
                  reloadTable={this.loadImporters}
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}
