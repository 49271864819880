import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { TopBarPortal } from '../helpers/admin_view';
import api from '../../../utils/pm_boards_api';
import ProjectQueueTable from './ProjectQueueTable';

const ProjectQueueShow = ({ sub_organization_filter, auth }) => {
  const { project_board_id, project_queue_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [projectQueue, setProjectQueue] = useState(null);

  useEffect(() => {
    document.getElementsByClassName('sub-navbar')[0].style.display = 'none';

    api.getQueue(project_board_id, project_queue_id, { sub_organization_filter })
      .then((response) => {
        setLoading(false);
        setProjectQueue(response.data.attributes);
      });

    return () => {
      document.getElementsByClassName('sub-navbar')[0].style.display = 'block';
    };
  }, [project_board_id, project_queue_id, sub_organization_filter]);

  const navigateToBoard = (id) => {
    document.getElementsByClassName('sub-navbar')[0].style.display = 'block';

    if (location.search.match(/lists/)) {
      navigate(`/projects_management/project_boards`);
    } else {
      navigate(`/projects_management/project_boards/${id}`);
    }
  };

  const navigateToMap = () => {
    if (location.search.match(/lists/)) {
      navigate(`/projects_management/project_boards/${project_board_id}/project_queues/${project_queue_id}/map_view?lists=true`);
    } else {
      navigate(`/projects_management/project_boards/${project_board_id}/project_queues/${project_queue_id}/map_view`);
    }
  };

  return (
    <div className='app px-4 pb-5 project-show'>
      {projectQueue && <TopBarPortal type="project_queue" record={projectQueue} />}
      <div className='container-fluid'>
        {projectQueue && (
          <div className='row mt-3 bg-white pt-3'>
            <div className='col-md-12 mb-3'>
              <ProjectQueueTable
                queue={projectQueue}
                sub_organization_filter={sub_organization_filter}
                user={auth.user}
                navigateToMap={navigateToMap}
                navigateToBoard={navigateToBoard}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectQueueShow;
