import React from "react";
import ProjectTaskLabel from "../ProjectTaskLabel";

const ProjectTasksAttachmentCreated = ({entry, from_group}) => {
  let { data, project_task } = entry
  let { project_task_file_template} = data

  return (
    <li className="timeline-item mb-4">
      { project_task && !from_group &&
        <ProjectTaskLabel project_task={project_task}/>
      }
      <h6 className="mb-1">
      {project_task_file_template} <span className="small">file template created</span>
      </h6>

      {!from_group && (
        <small className="text-secondary mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksAttachmentCreated
