import React, { Fragment } from 'react'
import Attachments from './Attachments'

const FixedAttachmentGroups = (props) => {
  let { fixed_attachments } = props

  let style = {
  }

  return(
    <Fragment>
      <h5 className="text-uppercase text-secondary">Files</h5>
      <hr/>

      { fixed_attachments.map((group) => {
        return(
          <div className='mb-4' key={group.identifier}>
            <h6 className="mb-2" style={style}>
              {group.label}
            </h6>

            <Attachments
              {...props}
              small_attachment_zone={true}
              fixed_attachment_identifier={group.identifier}
            />
          </div>
        )
      })}
    </Fragment>
  )
}

export default FixedAttachmentGroups
