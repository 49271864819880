import React, { Component, Fragment, useContext } from 'react'
import { render } from 'react-dom'
import { OrganizationContext } from '../../../contexts/OrganizationContext'

const JumpToMap = ({navigateToMap}) => {
  let context =  useContext(OrganizationContext)
  return(
    <span className='cursor-pointer pull-right' onClick={navigateToMap}>
      <img id="country-icon" src={context.country_icon} height="30"/>
    </span>
  )
}

export default class Card extends Component {
  constructor(props) {
    super(props)

    this.handleNavigate = this.handleNavigate.bind(this)
  }

  handleNavigate(e) {
    if(!this.props.onShow) {
      if(e.target.id == 'country-icon') {
        this.props.navigateToMap(this.props.queue)
      } else {
        this.props.navigateToQueue(this.props.queue)
      }
    }else {
      this.props.navigateToBoard(this.props.queue.project_board_id)
    }
  }

  render() {
    let { queue, onShow, navigateToMap } = this.props
    let style = {
      backgroundColor: queue.background_color,
      color: queue.text_color,
      cursor: 'pointer'
    }

    return(
      <div className="card shadow-sm mb-3" style={style} onClick={this.handleNavigate}>
        <div className="card-header d-flex justify-content-between">
          <div>{ queue.header }</div>
          { onShow &&
            <div className='pull-right' style={{cursor: 'pointer'}}>
              <i className='fa fa-share fa-flip-horizontal'></i>
            </div>
          }
          { !onShow &&
            <div className='pull-right ps-1'>
              <i className='fa fa-chevron-right'></i>
            </div>
          }
        </div>
        <div className="card-body">
          <div>
            <h3 className="card-title d-inline-block">
              {queue.stats.task_count}
            </h3>

            { !onShow && queue.jump_to_map &&
              <JumpToMap
                navigateToMap={this.handleNavigate}
              />
            }
          </div>

          { queue.description ? (
            <p className="card-text small">
              {queue.description}
            </p>
          ) : (
            <p className="card-text" style={{minHeight: '20px'}}>
            </p>
          )}
        </div>
      </div>
    )
  }
}
