import React, { Fragment, useState, useEffect } from 'react'

import SubOrganizationFilter from './SubOrganizationFilter'

import api from '../../../utils/pm_boards_api'

import ListCard from './ListCard'
import { useNavigate } from 'react-router-dom'

const BoardList = ({project_board, navigateToQueue, navigateToMap}) => {
  let style = {
    display: 'inline-block',
    width: '20rem',
  }

  return(
    <div style={style}>
      <div className="card bg-light" style={{maxHeight: '80vh', overflowY: 'auto', whiteSpace: 'break-spaces'}}>
        <div className="card-body">
          <h6 className="card-title text-uppercase text-truncate py-2">
            {project_board.name}
          </h6>
          <div className="items border border-light">
            { project_board.project_queues.map((queue) => {
              return(
                <ListCard
                  key={queue.id}
                  queue={queue}
                  navigateToQueue={navigateToQueue}
                  navigateToMap={navigateToMap}
                />
              )
            })}

          </div>
        </div>
      </div>
    </div>
  )
}

const ProjectBoardsIndex = ({ project_boards: initialProjectBoards, sub_organization_filter, setSubOrganizationFilter, auth }) => {
  const [loading, setLoading] = useState(true);
  const [projectBoards, setProjectBoards] = useState(initialProjectBoards || []);
  const navigate = useNavigate();

  const navigateToMap = (queue) => {
    navigate(`/projects_management/project_boards/${queue.project_board_id}/project_queues/${queue.id}/map_view?lists=true&&base=true`);
  };

  const navigateToQueue = (queue) => {
    navigate(`/projects_management/project_boards/${queue.project_board_id}/project_queues/${queue.id}?lists=true`);
  };

  const getBoardQueues = (filter) => {
    const updatedProjectBoards = projectBoards.map((board) => ({ ...board, project_queues: [] }));

    api.getAllQueues(filter).then((response) => {
      const queues = response.data || [];
      queues.forEach((queue) => {
        const projectBoard = updatedProjectBoards.find((e) => e.id === queue.attributes.project_board_id);
        if (projectBoard) {
          projectBoard.project_queues.push(queue.attributes);
        }
      });
      setProjectBoards(updatedProjectBoards);
      setLoading(false);
    });
  };

  useEffect(() => {
    getBoardQueues(sub_organization_filter);
  }, [sub_organization_filter]);

  const handleSetSubOrganizationFilter = (e) => {
    setLoading(true);
    getBoardQueues(e);
    setSubOrganizationFilter(e);
  };

  const boardStyle = {
    display: 'block',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  };

  return (
    <div className='app pb-5 project-show'>
      <div className='container-fluid mt-4'>
        <div className="row mb-2">
          <div className='col-md-8'></div>
          <div className='col-md-4'>
            <SubOrganizationFilter
              sub_organization_filter={sub_organization_filter}
              setSubOrganizationFilter={handleSetSubOrganizationFilter}
            />
          </div>
        </div>

        {loading ? (
          <Fragment />
        ) : (
          <div className="row">
            <div className='col-12'>
              <div className='board' style={boardStyle}>
                {projectBoards.map((project_board) => {
                  if (project_board.project_queues.length > 0) {
                    return (
                      <BoardList
                        key={project_board.id}
                        project_board={project_board}
                        navigateToQueue={navigateToQueue}
                        navigateToMap={navigateToMap}
                      />
                    );
                  } else {
                    return <Fragment key={project_board.id} />;
                  }
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectBoardsIndex;
