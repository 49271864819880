import React, { Component, Fragment } from 'react'

import PhoneInput, {formatPhoneNumber} from 'react-phone-number-input'

import Select from 'react-select'

import { UserStatusInfo } from './UserStatusInfo'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

export default class UserForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    this.handleValue = this.handleValue.bind(this)
    this.requiredClass = this.requiredClass.bind(this)
    this.requiredP = this.requiredP.bind(this)
    this.requiredL = this.requiredL.bind(this)
  }

  handleValue(type, e) {
    let model = {...this.props.model}
    if(type == 'name') {
      model[type] = e.target.value
    } else if(type == 'email') {
      model[type] = e.target.value
    } else if(type == 'password') {
      model[type] = e.target.value
    } else if(type == 'has_financial_access') {
      model[type] = e.target.checked
    } else if(type == 'has_external_analysis_access') {
      model[type] = e.target.checked
    } else if(type == 'should_change_password_on_login') {
      model[type] = e.target.checked
    } else if(type == 'has_timesheet') {
      model[type] = e.target.checked
    } else if(type == 'phone') {
      model[type] = e
    } else if(type == 'user_type') {
      model[type] = e.target.value
      let keys = Object.keys(model)
      keys.forEach((key) => {
        if(key.match(/pgt_/)) {
          model[key] = []
        }
      })
    } else if(type == 'sub_organization') {
      model[type] = e
    } else if(type == 'supervisor') {
      model[type] = e
    } else if(type == 'principal_role') {
      model[type] = e
    } else if(type == 'default_work_type') {
      model[type] = e
    } else if(type == 'user_configuration_template') {
      model[type] = e
      let config = e.config
      if(e.config) {
        model["principal_role"] = this.props.datasources.organization_roles.find(e => e.value == config.role )
        model.has_external_analysis_access = config.has_external_analysis_access
        model.has_financial_access = config.has_financial_access
        model.has_inventory_access = config.has_inventory_access
        model.has_timesheet = config.has_timesheet
        model.default_work_type = this.props.datasources.work_types.find(e => e.identifier == config.default_work_type)
      }

    } else if(type == 'secondary_roles') {
      model[type] = e
    } else if(type == 'pgt') {
      model[type] = e
    } else if(type.match(/pgt_/)) {
      model[type] = e
    }

    this.props.updateModel(model)
  }

  requiredClass(name) {
    let rclass = 'form-control '
    let invalid_fields = this.props.invalid_fields || []
    if(invalid_fields.includes(name)) {
      rclass = 'form-control is-invalid'
    }
    return rclass
  }

  requiredL(name) {
    let rclass = ''
    let invalid_fields = this.props.invalid_fields || []
    if(invalid_fields.includes(name)) {
      rclass = 'text-danger'
    }
    return rclass
  }

  requiredP(name) {
    let rclass = (<Fragment></Fragment>)
    let invalid_fields = this.props.invalid_fields || []
    if(invalid_fields.includes(name)) {
      rclass = (
        <div className="invalid-feedback">
          This field is required.
        </div>
      )
    }
    return rclass
  }

  render() {
    let { model, datasources, user } = this.props
    let roles = datasources.organization_roles
    let work_types = datasources.work_types
    let supervisors = datasources.supervisors

    roles = roles.filter(role => datasources.allowed_organization_roles.includes(role.value))

    let { organization_templates } = datasources

    let full_templates = [{value: 'none', label: "None"}, ...organization_templates]

    let disabled_access_config = model.user_configuration_template.value != 'none' && !user.super

    return (
      <div className="row my-3">
        <div className="col-md-6 border-right px-4">
          <div className="form-group">
            <label className={this.requiredL("name")}>Name</label>
            <input
              value={model.name}
              onChange={(e) => this.handleValue("name", e)}
              type="email"
              className={this.requiredClass("name")}
            />
            {this.requiredP("name")}
          </div>

          <div className="form-group">
            <label className={this.requiredL("email")}>Email</label>
            <input
              value={model.email}
              onChange={(e) => this.handleValue("email", e)}
              type="email"
              className={this.requiredClass("email")}
            />
            {this.requiredP("email")}
          </div>

          <div className="form-group">
            <label>Phone</label>

            <PhoneInput
              placeholder="Enter phone number"
              value={model.phone}
              defaultCountry="US"
              onChange={(e) => this.handleValue("phone", e)}
            />
          </div>

          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={model.password}
              onChange={(e) => this.handleValue("password", e)}
              className={this.requiredClass("password")}
            />
            {this.requiredP("password")}
            {this.props.show_password_message && (
              <small>Leave blank if you do not want to change</small>
            )}
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              id="login_check"
              onChange={(e) =>
                this.handleValue("should_change_password_on_login", e)
              }
              className="form-check-input"
            />
            <label className="form-check-label" htmlFor="login_check">
              User must change password on next login
            </label>
          </div>

          { user.register_supervisor &&
            <div className="form-group mt-3">
              <label>Supervisor</label>
              <Select
                styles={selectStyles}
                value={model.supervisor}
                isMulti={false}
                onChange={(e) => this.handleValue('supervisor', e) }
                options={supervisors}
                placeholder={'Select supervisor'}
                isDisabled={disabled_access_config}
              />
            </div>
          }
        </div>

        <div className="col-md-6 px-4">
          <div className="form-group">
            <UserStatusInfo user={model} />
          </div>

          <div className="form-group">
            <label className={this.requiredL("principal_role")}>Role</label>
            <Select
              isDisabled={disabled_access_config}
              styles={selectStyles}
              value={model.principal_role}
              isMulti={false}
              onChange={(e) => this.handleValue("principal_role", e)}
              options={roles}
              placeholder={"Select role"}
            />
          </div>
          { user.manage_timesheet &&
          <>
            <div className="form-group mt-3">
              <label>Default Work Type</label>
              <Select
                isDisabled={disabled_access_config}
                styles={selectStyles}
                value={model.default_work_type}
                isMulti={false}
                onChange={(e) => this.handleValue("default_work_type", e)}
                options={work_types}
                placeholder={"Select role"}
              />
            </div>
          </>
          }

          { user.manage_external_analysis &&
          <div className="form-check">
            <input
              type="checkbox"
              id="manage_external_analysis"
              checked={model.has_external_analysis_access}
              onChange={(e) => this.handleValue('has_external_analysis_access', e)}
              className="form-check-input"
              disabled={disabled_access_config}
            />
            <label className="form-check-label" htmlFor="manage_external_analysis">
              Has external analytics access
            </label>
          </div>
          }

          { user.handle_financial &&
            <div className="form-check">
              <input
                type="checkbox"
                id="financial_check"
                checked={model.has_financial_access}
                onChange={(e) => this.handleValue('has_financial_access', e)}
                className="form-check-input"
                disabled={disabled_access_config}
              />
              <label className="form-check-label" htmlFor="financial_check">
                Has financial access
              </label>
            </div>
          }
          { user.manage_timesheet &&
          <>
            <div className="form-check">
              <input
                type="checkbox"
                id="has_timesheet"
                checked={model.has_timesheet}
                onChange={(e) => this.handleValue('has_timesheet', e)}
                className="form-check-input"
                disabled={disabled_access_config}
              />
              <label className="form-check-label" htmlFor="has_timesheet">
                Has Timesheet Portal-Only Access
              </label>
            </div>
          </>
          }

          {organization_templates.length > 0 && (
            <div className="form-group bg-warn p-2">
              <hr />
              <h5 className="text-secondary">Settings Template</h5>
              <Select
                styles={selectStyles}
                value={model.user_configuration_template}
                isMulti={false}
                onChange={(e) =>
                  this.handleValue("user_configuration_template", e)
                }
                options={full_templates}
                placeholder={"Select template"}
              />
              <span className="text-secondary">
                <small>
                  Applying a template overrides role, groups, relationships, and checkbox values.
                </small>
              </span>
            </div>
            )
          }

        </div>
      </div>
    );
  }
}
