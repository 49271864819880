import React, { useState } from "react";
import IntervalGroup from "./IntervalGroup";
import { useCertifyPeriod } from "../hooks/useTimesheet";

const CertifiedZone = ({ report }) => {
  const [isOpen, setIsOpen] = useState(false);
  let { groups, report_label, total_hours } = report.report;

  if (isOpen) {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-end align-items-center">
              <div
                className="p-2 cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                <i
                  className="fa fa-chevron-up"
                  style={{ fontSize: "20px" }}
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {groups.map((group, index) => {
              return <IntervalGroup group={group} key={index} />;
            })}
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12 d-flex justify-content-end">
            <div className="fw-bold">
              <span className="me-4">Total</span>
              <span className="me-4 pe-2">{total_hours}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr />
            <div className="d-flex flex-row align-items-center">
              <i
                className="fa fa-check-circle text-success me-3"
                style={{ fontSize: "28px" }}
              ></i>

              <div className="che">
                <p className="mb-0">Horas Certificadas</p>
                <small className="text-secondary pb-0">
                  {report.certified_by_user_at} por {report.certified_by_user}
                </small>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h6 className="fw-bold mb-0">{report_label}</h6>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center">
              <i
                className="fa fa-check-circle text-success me-3"
                style={{ fontSize: "28px" }}
              ></i>

              <div className="che">
                <p className="mb-0">Horas Certificadas</p>
                <small className="text-secondary pb-0">
                  {report.certified_by_user_at} por {report.certified_by_user}
                </small>
              </div>
            </div>
            <div className="p-2 cursor-pointer" onClick={() => setIsOpen(true)}>
              <i
                className="fa fa-chevron-down"
                style={{ fontSize: "20px" }}
              ></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const NonCertifiedZone = ({ report }) => {
  let { groups, total_hours, report_label } = report.report;
  const { mutate, isLoading, isPending } = useCertifyPeriod();

  const certify = () => {
    mutate(report.id, {
      onSuccess: () => {
        console.log("certified");
      },
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          {[...groups].reverse().map((group, index) => {
            return <IntervalGroup group={group} key={index} />;
          })}
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-12 d-flex justify-content-end">
          <div className="fw-bold">
            <span className="me-4">Total</span>
            <span className="me-4 pe-2">{total_hours}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <hr />
          <div className="d-flex align-items-center">
            <button
              className="btn btn-primary"
              onClick={certify}
              disabled={isPending}
            >
              Certificar Horas
            </button>

            {isPending && (
              <div className="spinner-border ms-2 mb-0" role="status"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const IndividualReport = ({ report }) => {
  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {report.certified_by_user_at ? (
              <CertifiedZone report={report} />
            ) : (
              <NonCertifiedZone report={report} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualReport;
