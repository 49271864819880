import React, { Component } from 'react'
import { render } from 'react-dom'

import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

import UsersIndex from '../components/projects_management/users/UsersIndex'

export default class PmUsersContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Router>
        <Routes>
          <Route
            path="/projects_management/users"
            element={<UsersIndex auth={this.props.auth} />}
          />
        </Routes>
      </Router>
    )
  }
}
