import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Modal from 'react-bootstrap/Modal';

import general_api from '../../../utils/pm_api'
import pm_states_api from '../../../utils/pm_states_api'

import Select from 'react-select'
import axios from 'axios'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

class Group extends Component {
  render() {
    let { group, sub_organizations } = this.props.group
    let { disabled } = this.props

    let class_title = 'text-dark small text-uppercase'

    if(group.description) {
      class_title = 'text-dark small text-uppercase mb-0'
    }

    let values = this.props.values || []

    let options = sub_organizations
    let is_multiple = false

    return(
      <div className='px-2 py-3'>
        <h6 className={class_title}>
          {group.label}
        </h6>
        { group.description &&
          <span className='text-secondary' style={{fontSize: "12px"}}>{group.description}</span>
        }

        <div className=''>
          <Select
            styles={selectStyles}
            isMulti={is_multiple}
            value={values}
            onChange={(data) => this.props.handleValue(group.value, data)}
            options={options}
            placeholder={'Select Option'}
            isDisabled={disabled}
            isClearable={true}
          />
        </div>
      </div>
    )
  }
}


export default class RelationshipsModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      processing: false,
      relationships: [],
      open_confirm_modal: false,
      used_groups: {},
    }

    this.handleApply = this.handleApply.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.handleValue = this.handleValue.bind(this)
  }

  handleValue(group, values) {
    let { used_groups } = this.state
    used_groups[group] = values
    this.setState({used_groups})
  }

  handleClose() {
    if(this.state.processing) {
      return
    }

    this.props.closeModal()
  }

  handleApply() {
    let { processing, loading } = this.state
    if(processing || loading) {
      return
    }

    this.setState({processing: true}, () => {
      general_api.createProjectRelationships(this.props.project.id, this.state.used_groups).then((response) => {
        this.props.reloadProject()
      })
    })
  }

  componentWillMount() {
    general_api.getProjectRelationshipsData(this.props.project.id).then((response) => {
      let relationships = response.relationships
      let used_groups = response.used_groups
      this.setState({loading: false, relationships, used_groups})
    })
  }

  render() {
    let { project, is_open } = this.props
    let { relationships, loading, processing, used_groups } = this.state

    return(
      <Fragment>
        { !this.state.open_confirm_modal ? (
          <Modal show={is_open} onHide={this.handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>
                Relationships - {project.name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              { !loading &&
                <Fragment>
                  { relationships.map((group, index) => {
                    return(
                      <Group
                        key={index}
                        group={group}
                        values={used_groups[group.group.value]}
                        handleValue={this.handleValue}
                        disabled={processing}
                      />
                    )
                  })}
               </Fragment>
              }
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={this.handleClose}
                className={"btn btn-secondary " + (processing ? "disabled" : "")}
              >
                Close
              </button>

              <button className={"btn btn-primary " + (processing ? "disabled" : "")}
                onClick={this.handleApply}>
                Apply
              </button>
            </Modal.Footer>
          </Modal>

        ) : (
          <ConfirmModal
            handleClose={() => this.setState({open_confirm_modal: false})}
            handleConfirm={this.handleConfirm}
            processing={processing}
            project_label={project.name}
            template_label = {this.state.selected_template.name}
          />
        )}
      </Fragment>
    )
  }
}
