import React from "react";
import RightArrowSvg from "./RightArrowSvg";
import ProjectTaskLabel from "../ProjectTaskLabel";

const ProjectTasksScheduleDurationSet = ({entry, from_group}) => {
  let { data, project_task } = entry
  let { new_duration, past_duration, comment } = data

  return (
    <li className="timeline-item mb-4">
      { project_task && !from_group &&
        <ProjectTaskLabel project_task={project_task}/>
      }

      <h6 className="mb-1 small">
        schedule duration changed
      </h6>

      <div>
        {past_duration ? `${past_duration} min` : "No duration"}
        <RightArrowSvg />
        {new_duration ? `${new_duration} min` : "No duration"}
      </div>

      {comment && (
        <div
          className="px-3 py-1 mb-1"
          style={{ backgroundColor: "#e6ecf1", borderRadius: "5px" }}
        >
          <small>{comment}</small>
        </div>
      )}

      {!from_group && (
        <small className="text-secondary mb-1 fw-bold">
           {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksScheduleDurationSet
