import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import { useGetSignedUrls } from '../document_groups/hooks/useAttachments';
import UploadingFile from './UploadingFile';
import Modal from "react-bootstrap/Modal";
import uuid from 'node-uuid';

const UploadComponent = ({
  resource_type,
  resource_id,
  group_identifier,
  onClose,
  refetchAttachments,
}) => {
  const [processingFiles, setProcessingFiles] = useState([]);
  const { mutate: getSignedUrls, isLoading: isLoadingSignedUrls } =
    useGetSignedUrls();

  const onDrop = (files) => {
    let files_map = files.map((file) => {
      let size = (file.size / (1024 * 1024)).toFixed(2);
      return Object.assign(file, {
        ...file,
        preview: URL.createObjectURL(file),
        uuid: uuid.v4(),
        extension: file.name.split(".").pop(),
        show_size: `${size} MB`,
      });
    });

    setProcessingFiles((prevValues) => [...files_map, ...prevValues]);

    let files_data = files_map.map((file) => {
      return {
        name: file.name,
        uuid: file.uuid,
        extension: file.extension,
        type: file.type,
      };
    });

    let params = {
      resource_type,
      resource_id,
      files: files_data,
      group_identifier,
    };

    getSignedUrls(params, {
      onSuccess: ({ signed_urls }) => {
        setProcessingFiles((prevValues) => {
          let newValues = prevValues.map((file) => {
            let upload_data = signed_urls[file.uuid];
            if (upload_data) {
              return Object.assign(file, upload_data);
            } else {
              return file;
            }
          });

          return newValues;
        });
      },
    });
  };

  return (
    <Modal show={true} onHide={onClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title className="h5">Upload Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ minHeight: "160px" }}>
          <div className="row">
            <div className="col-12">
              <Dropzone
                className="dropzone py-4 mb-2"
                style={{ border: "2px dashed #ddd" }}
                multiple={true}
                onDrop={onDrop}
              >
                <center className="text-secondary">
                  <u>
                    <span className="fw-bold">Choose files</span>
                  </u>{" "}
                  or drag them here
                </center>
              </Dropzone>
            </div>
          </div>

          <div className="row">
            {processingFiles.map((file) => {
              return (
                <div className="col-12" key={file.uuid}>
                  <UploadingFile
                    resource_type={resource_type}
                    resource_id={resource_id}
                    group_identifier={group_identifier}
                    file={file}
                    refetchAttachments={refetchAttachments}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadComponent;
