import React, { Fragment, useState, useEffect } from 'react'
import { render } from 'react-dom'
import ContentLoader from "react-content-loader"

import StateLabel from '../common/StateLabel.js'
import RelationshipsProjectTaskBatchModal from '../helpers/RelationshipsProjectTaskBatchModal'
import SupportRelationshipsProjectTaskBatchModal from '../helpers/SupportRelationshipsProjectTaskBatchModal'
import ProjectTaskBatchModal from '../helpers/ProjectTaskBatchModal'

import CheckJobState from '../helpers/CheckJobState'

import { assignedTo } from '../helpers/groupHelpers'

const infoStyles = {
  fontSize: '14px',
  marginBottom: '2px',
  color: '#555',
};

const createInfoLine = (label, value) => {
  return value !== undefined && value !== null && value !== ''
    ? (
      <div style={infoStyles}>
        <span className="text-secondary">{label}:</span> <strong>{value}</strong>
      </div>
    ) : null;
};

const SelectedSection = ({
  selected_records,
  setSelectedIds,
  selected_ids,
  active_job_id,
  setSchedule,
  scheduleable_project_task_id,
  user,
  infoMap,
  setInfoMap
}) => {
  let b_selected_ids = [...selected_ids]

  const removeRecord = (record_id) => {
    const index = b_selected_ids.indexOf(record_id)
    if (index > -1) {
      b_selected_ids.splice(index, 1);
    }

    setSelectedIds(b_selected_ids)
    setInfoMap(prevInfoMap => {
      delete prevInfoMap[record_id]
      return prevInfoMap
    })
  }

  if(selected_records.length == 0) {
    return(
      <Fragment>
      </Fragment>
    )
  }


  return(
    <div className='overflow-auto' style={{height: '65vh'}}>
      { selected_records.map((record, index) => {
        let block_class = record.id == scheduleable_project_task_id ? 'd-block border-left ps-2 border-success' : 'd-block'
        let assignedToName = assignedTo(record, user)
        return(
          <div className='py-2 border-bottom overflow-auto' key={record.id}>
            <div className={block_class}>
              <div>
                <a className='text-dark' href={"/projects_management/projects/" + record.project_id} target="_blank">
                  {record.project.name}
                </a>

                <small className='text-info pull-right'>
                  {record.project.sub_organization}
                </small>
              </div>

              <div className='small d-inline-block text-secondary text-uppercase'>
                <StateLabel state={record.state} />
              </div>

              { !active_job_id &&
                <div className='small d-inline block pull-right text-secondary mt-1 me-2 cursor-pointer' onClick={() => removeRecord(record.id)}>
                  <i className="fa fa-times"></i>
                </div>
              }

              { record.has_scheduling_calendar_behaviour &&
                <div className="pb-1">

                  { assignedToName &&
                  <small className=''>
                    {assignedToName}
                  </small>
                  }


                  { user.sub_organization_user ? (
                    <Fragment>
                      { record.scheduled_at_label ? (
                        <div className='cursor-pointer' onClick={() => setSchedule(record)}>
                          <small className='text-primary'>
                            <i className='fa fa-calendar'></i> {record.scheduled_at_label}
                          </small>
                        </div>
                      ) : (
                        <div className='cursor-pointer' onClick={() => setSchedule(record)}>
                          <small className='text-secondary'>
                            <i className='fa fa-calendar'></i> Pending Scheduling
                          </small>
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    <div className='cursor-pointer' onClick={() => setSchedule(record)}>
                      <small className='text-info'>
                        <i className='fa fa-calendar'></i> {record.scheduled_at_label || 'Schedule'}
                      </small>
                    </div>
                  )}
                </div>
              }

            </div>

            <div className="">
              {infoMap[record.id] && infoMap[record.id].map_info.map((info, index) => {
                return(
                  <div className="d-flex" key={index}>
                    {createInfoLine(info.label, info.value)}
                  </div>
                )
              })}
              </div>

          </div>
        )
      })}
    </div>
  )
}

const MapSideBar = ({
  loading, records, selected_ids, setSelectedIds, user, handleEditSave,
  active_job_id,
  setActiveJobId,
  title,
  setSchedule,
  scheduleable_project_task_id,
  entity,
  sub_organization_filter,
  infoMap,
  setInfoMap
}) => {
  const selected_records = records.filter(r => selected_ids.includes(r.id))
  const [open_selected_modal, setOpenSelectedModal] = useState(false)
  const [open_support_selected_modal, setOpenSupportSelectedModal] = useState(false)
  const [open_single_selected_modal, setOpenSingleSelectedModal] = useState(false)
  const [update_state, setUpdateState] = useState({})

  const checkUpdate = (job_id) => {
    setActiveJobId(job_id)
    setOpenSelectedModal(false)
    setOpenSupportSelectedModal(false)
  }

  const finishUpdate = (data) => {
    setActiveJobId(undefined)
    handleEditSave()
    setUpdateState(data)
  }

  let single_selected_button = (
    <button className='btn btn-primary btn-block mb-4' onClick={() => setOpenSingleSelectedModal(!open_single_selected_modal)}>
      Update Selected
    </button>
  )

  let update_selected_button = (
    <button className='btn btn-primary btn-block mb-4' onClick={() => setOpenSelectedModal(!open_selected_modal)}>
      Update Selected
    </button>
  )

  let support_update_selected_button = (
    <button className='btn btn-primary btn-block' onClick={() => setOpenSupportSelectedModal(!open_support_selected_modal)}>
      Update Selected
    </button>
  )

  if( active_job_id ) {
    update_selected_button = (
      <button className='mt-2 btn btn-primary btn-block disabled' disabled>
        Processing updates ...
      </button>
    )

    support_update_selected_button = (
      <button className='mt-2 btn btn-primary btn-block disabled' disabled>
        Processing updates ...
      </button>
    )
  }

  const handleClear = () => {
    setSelectedIds([])
    setInfoMap({})
  }

  return(
    <div className='mx-2'>
      <div className='card p-1'>
        <div className='card-body p-1'>
          <h5 className='mb-3'>
            {title}
          </h5>

          { loading ? (
            <GlobalSidebar />
          ) : (
            <Fragment>
              { update_state.state &&
                <div className="alert alert-primary" role="alert">
                  {update_state.message}
                  <button type="button" className="close" onClick={() => setUpdateState({})}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              }

              { sub_organization_filter &&
                <div className="mb-2">
                  <small>Active Filter:</small>
                  <span className="ms-2 badge badge-info">
                    {sub_organization_filter.label}
                  </span>
                </div>
              }

              <h5 className='text-secondary mb-0'>
                {selected_ids.length} Selected
              </h5>

              { !active_job_id &&
                <div className="text-primary my-3">
                  <span className='cursor-pointer' onClick={() => setSelectedIds(records.map(e => e.id))}>
                    add all visible
                  </span>

                  <span className='cursor-pointer ms-4' onClick={handleClear}>
                    clear
                  </span>
                </div>
              }

              { selected_ids.length > 0 &&
                <Fragment>
                  { entity.hierarchy_type == 'default' ? (
                    <Fragment>
                      {single_selected_button}
                    </Fragment>
                  ) : (
                    <Fragment>
                      { !user.support &&
                        <Fragment>
                          {update_selected_button}
                        </Fragment>
                      }
                      { user.support &&
                        <Fragment>
                          {support_update_selected_button}
                        </Fragment>

                      }
                    </Fragment>
                  )}
                </Fragment>
              }

              <SelectedSection
                selected_records={selected_records}
                setSelectedIds={setSelectedIds}
                selected_ids={selected_ids}
                active_job_id={active_job_id}
                setSchedule={setSchedule}
                scheduleable_project_task_id={scheduleable_project_task_id}
                user={user}
                infoMap={infoMap}
                setInfoMap={setInfoMap}
              />
            </Fragment>
          )}

          { open_single_selected_modal &&
            <ProjectTaskBatchModal
              is_open={true}
              closeModal={() => setOpenSingleSelectedModal(false)}
              handleSave={handleEditSave}
              user={user}
              tasks={[]}
              load_tasks={true}
              selected_ids={selected_ids}
            />
          }

          { open_selected_modal &&
            <RelationshipsProjectTaskBatchModal
              checkUpdate={checkUpdate}
              closeModal={() => setOpenSelectedModal(false)}
              handleSave={handleEditSave}
              selected_ids={selected_ids}
              user={user}
            />
          }

          { open_support_selected_modal &&
            <SupportRelationshipsProjectTaskBatchModal
              checkUpdate={checkUpdate}
              closeModal={() => setOpenSupportSelectedModal(false)}
              handleSave={handleEditSave}
              selected_ids={selected_ids}
              user={user}
            />
          }

          { active_job_id &&
            <CheckJobState
              active_job_id={active_job_id}
              finishUpdate={finishUpdate}
            />
          }
        </div>
      </div>
    </div>
  )
}

const GlobalSidebar = props => {
  return (
    <ContentLoader
    speed={2}
    width={300}
    height={615}
    viewBox="0 0 300 615"

    {...props}
  >
    <rect x="79" y="20" rx="0" ry="0" width="0" height="1" />
    <rect x="4" y="1" rx="0" ry="0" width="3" height="600" />
    <rect x="4" y="598" rx="0" ry="0" width="294" height="3" />
    <rect x="158" y="596" rx="0" ry="0" width="5" height="3" />
    <rect x="5" y="1" rx="0" ry="0" width="294" height="3" />
    <rect x="296" y="1" rx="0" ry="0" width="3" height="600" />
    <rect x="5" y="60" rx="0" ry="0" width="294" height="3" />
    <rect x="22" y="20" rx="0" ry="0" width="129" height="23" />
    <rect x="35" y="76" rx="4" ry="4" width="81" height="9" />
    <rect x="271" y="22" rx="4" ry="4" width="18" height="18" />
    <rect x="186" y="76" rx="4" ry="4" width="81" height="9" />
    <rect x="150" y="63" rx="0" ry="0" width="2" height="44" />
    <rect x="6" y="104" rx="0" ry="0" width="144" height="3" />
    <rect x="152" y="106" rx="0" ry="0" width="145" height="1" />
    <rect x="28" y="127" rx="4" ry="4" width="243" height="31" />
    <rect x="62" y="188" rx="4" ry="4" width="148" height="19" />
    <circle cx="39" cy="197" r="10" />
    <circle cx="39" cy="247" r="10" />
    <circle cx="39" cy="297" r="10" />
    <circle cx="39" cy="347" r="10" />
    <rect x="64" y="237" rx="4" ry="4" width="148" height="19" />
    <rect x="65" y="287" rx="4" ry="4" width="148" height="19" />
    <rect x="64" y="337" rx="4" ry="4" width="148" height="19" />
    <circle cx="39" cy="547" r="10" />
  </ContentLoader>
  )
}
export default MapSideBar
