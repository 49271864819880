import React, { Fragment, useState, useEffect } from 'react'
import { render } from 'react-dom'
import DayView from './DayView'
import custom_hooks from './custom_hooks'

const WeekView = ({base_date, loading, records, time_now, selectDate, config, not_working_days, blocked_hours}) => {
  let start_on = base_date.startOf('isoweek')
  let days = [start_on]
  for (var i = 1; i < 7; i++) {
    days.push(start_on.clone().add(i, 'days'))
  }

  return(
    <Fragment>
      <div className='row mt-2'>
        { days.map((day, index) => {
          let is_weekend = index >= 5
          let col_size = is_weekend ? 'col-1 col-sm-6 col-md-1 px-1' : 'col-2 col-sm-6 col-md-2'
          let blocked_hours_array = blocked_hours[day.format('YYYY-MM-DD')] || []

          return(
            <div className={col_size} key={index}>
              <DayView
                is_weekend={is_weekend}
                config={config}
                time_now={time_now}
                loading={loading}
                day={day}
                records={records}
                selectDate={selectDate}
                not_working_days={not_working_days}
                blocked_hours={blocked_hours_array}
              />
            </div>
          )
        })}

      </div>
    </Fragment>
  )
}

const WeeksManager = ({
  today,
  time_now,
  selectDate,
  config,
  datasources,
  returnToMapView,
  comes_from_map,
  project_task_ids,
  title
}) => {
  const [base_date, setBaseDate] = useState(time_now.clone())
  const [loading, {records, not_working_days, blocked_hours}] = custom_hooks.
    getWeekRecords(base_date, project_task_ids)

  let start_date = base_date.clone().startOf('isoweek')
  let end_date = base_date.clone().add(1, 'week').endOf('isoweek')

  let can_go_back = start_date > today

  const handleWeekNavigation = (type) => {
    if(!can_go_back && type == 'back') {
      return
    }

    let new_date = null

    if(can_go_back && type == 'back') {
      new_date = base_date.clone().subtract(2, 'weeks')
    } else if (type == 'next') {
      new_date = base_date.clone().add(2, 'weeks')
    }

    setBaseDate(new_date)
  }

  return(
    <Fragment>
      <div className='row mb-2'>
        <div className='col-md-12 col-lg-12'>
          <div className="rbc-toolbar mb-0 ms-2 d-flex flex-row align-items-center justify-content-between ">
            <div className='d-flex'>
              <div className="rbc-btn-group">
                { comes_from_map ? (
                  <button
                    type="button"
                    onClick={returnToMapView}
                  >
                    Map View
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={returnToMapView}
                  >
                    Close
                  </button>
                )}
              </div>

              { title &&
                <div className='d-inline-flex ms-2 flex-column'>
                  <h6 className='mb-0'>{title}</h6>
                  <span className='text-secondary'>Update Schedule</span>
                </div>
              }
            </div>


            <div className=' mt-2'>
              <h5>
                <i className={'fa fa-chevron-left cursor-pointer me-2 px-2 ' + (can_go_back ? "" : "d-none")} onClick={() => handleWeekNavigation('back')}/>
                 Week of {start_date.format("MMM DD")} to {end_date.format("MMM DD, YYYY")}
                <i className='fa fa-chevron-right cursor-pointer ms-2 px-2' onClick={() => handleWeekNavigation('next')} />
              </h5>
            </div>

          </div>
        </div>
      </div>


      <div className='row mb-3 mx-2'>
        <div className='col-md-12 col-lg-12 p-4 border rounded'>
          <WeekView
            time_now={time_now}
            loading={loading}
            base_date={base_date.clone()}
            records={records}
            selectDate={selectDate}
            config={config}
            not_working_days={not_working_days}
            blocked_hours={blocked_hours}
          />
        </div>
      </div>

      <div className='row mx-2'>
        <div className='col-md-12 col-lg-12 p-4 border rounded'>
          <WeekView
            time_now={time_now}
            base_date={base_date.clone().add(1, 'week')}
            loading={loading}
            records={records}
            selectDate={selectDate}
            config={config}
            not_working_days={not_working_days}
            blocked_hours={blocked_hours}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default WeeksManager
