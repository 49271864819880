import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ProjectsIndex from '../components/projects_management/ProjectsIndex';
import ProjectsShow from '../components/projects_management/ProjectsShow';
import ProjectTaskShow from '../components/projects_management/ProjectTaskShow';
import TaskOrderShow from '../components/projects_management/task_views/order_task/TaskOrderShow';
import FormTaskEditor from '../components/projects_management/task_views/form_task/FormTaskEditor';

import { OrganizationContext } from '../contexts/OrganizationContext';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient();

const PmProjectsContainer = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <OrganizationContext.Provider value={props.auth}>
          <Routes>
            <Route
              path="/projects_management/projects"
              element={<ProjectsIndex auth={props.auth} />}
            />
            <Route
              path="/projects_management/projects/:project_id"
              element={<ProjectsShow auth={props.auth} />}
            />
            <Route
              path="/projects_management/project_tasks/:project_task_id"
              element={
                <ProjectTaskShow
                  show_admin_view={props.show_admin_view}
                  auth={props.auth}
                />
              }
            />
            <Route
              path="/projects_management/project_tasks/:project_task_id/task_orders/:task_order_id"
              element={<TaskOrderShow auth={props.auth} />}
            />
            <Route
              path="/projects_management/project_tasks/:project_task_id/form_editor"
              element={<FormTaskEditor />}
            />
          </Routes>
        </OrganizationContext.Provider>
      </Router>
    </QueryClientProvider>
  );
};

export default PmProjectsContainer;
