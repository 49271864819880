import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import { ShowHeaderLoader, ShowTabsLoader } from '../helpers/Loaders'

import api from '../../../utils/pm_sub_organizations_api'
import pm_states_api from '../../../utils/pm_states_api'
import axios from 'axios'
import StateDropdown from '../StateDropdown'
import { Link } from "react-router-dom"

import ConfigurationModal from '../projects/ConfigurationModal'

export default class ShowHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      sub_organization: null,
      states: [],
      open_configuration_modal: false,
    }

    this.handleStateSelect = this.handleStateSelect.bind(this)
    this.reloadOrganization = this.reloadOrganization.bind(this)
    this.reloadProject = this.reloadProject.bind(this)
  }

  reloadProject() {
    location.reload()
  }

  reloadOrganization() {
    let id = this.props.sub_organization_id
    api.getSubOrganization(id).then((response) => {
      this.setState({
        sub_organization: response.data.attributes,
      })
    })
  }

  handleStateSelect(state) {
    let data = {
      resource_type: 'sub_organizations',
      resource_id: this.state.sub_organization.id,
      state: state,
    }
    pm_states_api.updateResourceState(data).then((response) => {
      this.reloadOrganization()
    })
  }

  componentWillMount() {
    document.getElementsByClassName('sub-navbar')[0].style.display = 'none';
    let id = this.props.sub_organization_id
    axios.all([api.getSubOrganization(id), pm_states_api.getStates({resource_type: 'sub_organizations'})]).then((responseArray) => {
      this.setState({
        loading: false,
        sub_organization: responseArray[0].data.attributes,
        states: responseArray[1].data.map(d => d.attributes),
      })
    })
  }

  render() {
    let model = this.state.sub_organization
    let project = this.props.project || {}
    let { user } = this.props.auth
    let policy = this.props.auth.projects || {}

    return(
      <Fragment>
        { this.state.loading ? (
          <ShowHeaderLoader />
        ) : (
          <div className='bg-white px-4 py-3 pm__header'>
            <div className='row'>
              { !user.sub_organization_user &&
              <div className='col-md-12'>
                { this.props.onManageUsers ? (
                <div className="my-2 d-flex justify-content-between bd-highlight">
                  <small
                    onClick={() => this.props.navigate('/projects_management/sub_organizations')}
                    className="bd-highlight text-secondary text-uppercase"
                    style={{cursor: 'pointer'}}>
                    <i className='fa fa-chevron-left'></i> Back to contractors
                  </small>
                </div>
                ) : (
                <div className="my-2 d-flex justify-content-between bd-highlight">
                  <a href="/projects_management/sub_organizations">
                    <small
                      className="bd-highlight text-secondary text-uppercase"
                    >
                      <i className='fa fa-chevron-left'></i> Back to contractors
                    </small>
                  </a>
                </div>
                )}

              </div>

              }

              <div className='col-md-7'>
                <div className='model-meta'>
                  <h4 className='mb-0'>{model.name}</h4>
                  { model.contact_email &&
                  <div>
                    {model.contact_name} - {model.contact_email}
                  </div>
                  }

                  { !model.contact_email &&
                  <div>
                    {model.contact_name}
                  </div>
                  }

                  { model.contact_phone_number &&
                  <div>
                    <i className='fa fa-phone'></i> {model.contact_phone_number}
                  </div>
                  }

                  <div className='my-2'>
                    { this.props.onManageUsers ? (
                      <Fragment>
                        <small className="text-secondary fw-bold">Manage Users</small>
                        <span className="mx-2">|</span>
                        <a href={"/projects_management/projects/" + model.profile_project_id} className="text-info">
                          <small>Manage Documentation</small>
                        </a>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <a href={"/projects_management/sub_organizations/" + model.id} className="text-info">
                          <small>Manage Users</small>
                        </a>
                        <span className="mx-2">|</span>
                        <small className="text-secondary fw-bold">Manage Documentation</small>
                      </Fragment>
                    )}

                    { policy.can_configure && project.has_layered_templates &&
                    <Fragment>
                      <small>
                        <i className='fa fa-cog ms-2 me-1 text-secondary'></i>
                        <a className='text-info' href="#" onClick={() => this.setState({open_configuration_modal: true})}>
                          Configuration
                        </a>
                      </small>
                    </Fragment>
                    }
                  </div>
                </div>
              </div>

              { !user.sub_organization_user &&
                <div className='col-md-5'>
                  <div className='project-status pull-right'>
                    <StateDropdown
                        states={this.state.states}
                        current_state={model.state}
                        can_change_state={model.access.can_change_state}
                        handleStateSelect={this.handleStateSelect}
                      />

                    <h6 className="pt-2">
                      <small>
                        <span className='text-secondary text-uppercase'>Last Updated: </span> {model.updated_at_label}
                      </small>
                    </h6>
                  </div>
                </div>
              }
              {this.state.open_configuration_modal &&
                <ConfigurationModal
                  project={project}
                  closeModal={() => this.setState({open_configuration_modal: false})}
                  is_open={this.state.open_configuration_modal}
                  reloadProject={this.reloadProject}
                />
              }
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}


