import React, { Fragment, useState, useEffect } from 'react'
import { render } from 'react-dom'

import Modal from 'react-bootstrap/Modal'

import moment from 'moment'
import schedules_api from '../../../../utils/pm_project_schedules_api'

const CreateSchedule = ({handleClose, users, project_tasks, project_task_event, handleUpdateDatasources, config, slot}) => {
  const [processing, setProcessing] = useState(false)

  const [form, setForm] = useState({
    project_task: undefined,
    start_at: moment(project_task_event.start_at),
    end_at: moment(project_task_event.end_at),
    user: users.find(e => e.resourceId == slot.resourceId)
  })

  const handleSave = () => {
    if(processing) {
      return
    }

    setProcessing(true)
    let new_form = {...form, event_id: project_task_event.id}

    schedules_api.createEvent(new_form).then((response) => {
      handleUpdateDatasources()
    })
  }

  useEffect(() => {
    if(project_tasks.length == 1) {
      setForm({...form, project_task: project_tasks[0]})
    }
  }, [])

  const updateProjectTask = (e) => {
    setForm({...form, project_task: e})
  }

  let blocked = !form.project_task
  let is_update = project_task_event.resourceId

  let date_value = `${moment(slot.start).format("MMM/DD/YYYY")} from ${form.start_at.format("h:mm A")} to ${form.end_at.format("h:mm A")}`

  let prev_user = users.find(e => e.resourceId == project_task_event.resourceId)

  return(
    <Modal show={true} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>
          {is_update ? "Update Assignation" : "Assign User"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { is_update &&
          <div className="alert alert-success">
            Already assigned to <strong>{prev_user?.resourceTitle}</strong>.
          </div>
        }

        <div className="form-group">
          <label>Project task</label>
          <input
            className='form-control'
            value={form.project_task?.label} disabled/>
        </div>

        <div className="form-group">
          <label>User</label>
          <input
            className='form-control'
            value={form.user.resourceTitle} disabled/>
        </div>

        <div className="form-group">
          <label>Date</label>
          <input
            className='form-control'
            value={date_value} disabled/>
        </div>
      </Modal.Body>
      <Modal.Footer>
        { (processing) ? (
          <span className='text-secondary'>
            <i className='fa fa-spinner fa-spin fa-fw' style={{fontSize: '20px'}}></i>
          </span>
        ) : (
          <button onClick={handleClose} className="btn btn-secondary">Cancel</button>
        )}
        <button className="btn btn-primary" onClick={handleSave} disabled={processing || blocked}>
          {is_update ? "Update" : "Assign" }
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateSchedule
