import React from 'react';

const Label = ({ bgColor, children }) => {
  return (
    <span className='text-uppercase small'>
      <i
        className="fa fa-square me-1"
        style={{color: bgColor}}
      ></i>

     {children}
    </span>
  );
};

export default Label;
