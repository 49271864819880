import React, { Fragment } from 'react'
import { test_tasks, test_tasks2, test_tasks3 } from './__tests__'

export const setInitialTasks = (tasks, only_view) => {
  let new_identifier_map = {}
  let used_new_numbers = []
  //tasks = test_tasks3
  let new_tasks = []

  tasks.forEach((task) => {
    if(task.process_type === 'new') {
      new_tasks.push(task)
      if(new_identifier_map[task.identifier]) {
        new_identifier_map[task.identifier].push(task)
      } else {
        new_identifier_map[task.identifier] = [task]
      }
    }
  })

  tasks.forEach((task) => {
    if(task.process_type === 'to_delete' && task.db_record_exists && !task.db_record_deleted  && !task.previous_revised) {
      if(new_identifier_map[task.identifier] && new_identifier_map[task.identifier].length == 1) {
        if(!task.transfer_to_number) {
          task.transfer_to_number = new_identifier_map[task.identifier][0].number
        }
        used_new_numbers.push(task.transfer_to_number)
      }
    }
  })

  return {tasks, new_identifier_map, used_new_numbers, new_tasks}
}

export const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 101
  },
  content : {
    position                   : 'absolute',
    top                        : '3%',
    left                       : '3%',
    right                      : '3%',
    bottom                     : '6%',
    border                     : '1px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '20px'

  }
}

export const ProcessType = (props) => {
  let { task, v2 } = props
  if(!['new', 'to_delete', 'to_edit'].includes(task.process_type)) {
    return(<Fragment></Fragment>)
  } else {

    let class_type = 'ms-2 text-uppercase '
    let label = 'Not processable'

    if(task.process_type == 'to_delete') {
      label = v2 ? 'Revised' : 'Removed'
      class_type = class_type + 'text-danger'
    } else if(task.process_type == 'to_edit' ){
      label = 'Updated'
      class_type = class_type + 'text-secondary'

      if(task.note != task.past_note) {
        label = 'Notes Updated'
      }

    } else if(task.process_type == 'new' ){
      label = 'New'
      class_type = class_type + 'text-success'
    }

    return(
      <small className={class_type}>{label}</small>
    )
  }
}

export const differentValue = (value1, value2) => {
  let val = true
  if(value1 && value2) {
    val = parseFloat(value1) != parseFloat(value2)
  } else {
    val = value1 != value2
  }
  return val
}
