import React, { Component, Fragment, useState } from 'react'

import Comments from '../helpers/Comments'
import UsersTable from './UsersTable'
import ShowHeader from './SubOrganizationHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
const queryString = require('query-string')

class ShowTabs extends Component {
  constructor(props) {
    super(props)
    let tab = 'users'
    this.state = {
      loading: false,
      tab: tab,
      view_type: 'tasks',
    }

    this.changeActiveTab = this.changeActiveTab.bind(this)
    this.showComponent = this.showComponent.bind(this)
  }

  changeActiveTab(tab) {
    this.setState({tab, active_group: null})
  }

  showComponent() {
    let component = (<div></div>)
    let { user } = this.props
    switch(this.state.tab) {
      case 'users':
        component = (
          <UsersTable
            sub_organization_id={this.props.sub_organization_id}
            user={user}
          />
        )
        break;
      case 'comments':
        component = (
          <Comments
            resource_type="sub_organizations"
            resource_id={this.props.sub_organization_id}
            user={user}
          />
        )
        break
    }
    return component
  }

  render() {
    let { tab } = this.state

    return(
      <Fragment>
        { this.state.loading ? (
          <ShowTabsLoader />
        ) : (
          <div className='bg-white px-4 py-3 model-tabs'>
            <div className='row'>
              <div className='col-md-12'>
                <ul className="nav nav-tabs">
                  <li className="nav-item" onClick={() => this.changeActiveTab('users')}>
                    <a className={"nav-link " + (tab == 'users' ? 'active' : '')}>
                      <i className='fa fa-users'></i> People
                    </a>
                  </li>

                  <li className="nav-item" onClick={() => this.changeActiveTab('comments')}>
                    <a className={"nav-link " + (tab == 'comments' ? 'active' : '')}>
                      <i className='fa fa-comments'></i> Comments
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-md-12'>
                {this.showComponent()}
              </div>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

const SubOrganizationsShow = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [tab, setTab] = useState(queryString.parse(location.search).tab);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const auth = props.auth;

  return (
    <div className='app pb-5'>
      <div className='container pb-2'>
        <div className='row'>
          <div className='col-md-12'>
            <ShowHeader
              navigate={handleNavigate}
              sub_organization_id={id}
              auth={auth}
              onManageUsers={true}
            />
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-md-12'>
            <ShowTabs
              tab={tab}
              sub_organization_id={id}
              user={auth.user}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubOrganizationsShow;
